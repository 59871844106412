.formContainer {
  margin: 2rem auto;
  width: 33%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  padding: 20px 5px 20px 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  font-family: "Arial", sans-serif;
}

.editBatchScrollable {
  max-height: 70vh;
  overflow: auto;
  padding-right: 16px;
}

.editBatchScrollable::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.editBatchScrollable::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.editBatchScrollable::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.closeIconContainer {
  display: flex !important;
  justify-content: end !important;
  margin-top: -20px;
  margin-right: -5px;
  margin-bottom: 10px;
}

.formContainerTitle {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-top: -30px;
  margin-bottom: 15px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  text-align: start;
}

.inputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.inputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.saveButtonContainer {
  display: flex;
  justify-content: center;
  margin: 0.5rem;
}

.accordionContainer {
  padding: 0.5rem 0;
  width: 90%;
  margin: 0.2px auto;
}

.accordionContainerTitle {
  color: #333333;
  text-align: center;
  font-weight: light;
}

.theAccordion {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5) !important;
  background-color: #edf0f9 !important;
}

.accordionContent {
  height: 10px !important;
  margin: 10px 0 !important;
}

.accordionEdit {
  margin-left: 10px !important;
  font-size: 1.2rem !important;
}

.error {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .formContainer {
    width: 95vw;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .formContainer {
    width: 75vw;
    margin-top: 3rem !important;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .formContainer {
    width: 60vw;
    margin-top: 3rem !important;
  }
}
