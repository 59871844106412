.pieChartContainer {
  background-color: rgb(226, 239, 250);
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  max-width: 260px;
  margin: 10px auto;
  padding: 0.5rem;
  height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
}

.chartContent {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}

.pieChartContainer:hover {
  transform: scale(1.1);
  transition: transform 300ms;
}

.subjectName {
  text-align: center;
  margin: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.noLecturesMessage {
  text-align: center;
  color: red;
  width: 80%;
}

.subjectCode {
  text-align: center;
  font-weight: bold;
  margin-top: -5px;
}

.totalCount {
  text-align: center;
  position: relative;
}

.subjectContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
}

.noData {
  margin-top: 3rem !important;
  margin-bottom: -2rem !important;
  text-align: center !important;
  color: red;
  font-size: 1.1rem;
}

@media screen and (min-width: 855px) and (max-width: 1160px) {
}
