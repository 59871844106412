.settingsContainer {
  display: flex;
  flex-direction: column;
}

.headingSettings {
  line-height: 1.9rem;
  color: rgb(40, 44, 63);
}

.settingsListContainer {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  padding: 2rem;
  gap: 2rem;
  margin: 15px 0;
  width: 100%;
  max-width: 1520px;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 2rem;
}

.horizontalRow {
  margin-top: 10px;
  width: 100%;
}

.navList {
  margin-top: 1rem;
  list-style-type: none;
  padding: 0;
}

.navItem {
  margin: 0.5rem 0;
  font-size: 1.2rem;
}

.navList a {
  display: block;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: #e3dfdf;
  transition: background-color 0.3s ease;
  text-decoration: none;
  color: #333;
}

.navList a:hover {
  background-color: #69c;
  font-size: 1.5rem;
  color: white;
}

.icon {
  height: 35px;
  margin-top: -10px;
  margin-left: 5px;
  vertical-align: middle;
}

@media screen and (max-width: 600px) {
  .settingsListContainer {
    width: 92vw;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .settingsListContainer {
    width: 93vw;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .settingsListContainer {
    width: 89vw;
  }
}
