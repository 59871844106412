.containerTable {
  margin: 1rem auto !important;
  width: 95% !important;
  max-height: 600px !important;
}

.theTable {
  border: 1px solid black !important;
}

.tableHead {
  background-color: rgb(88, 147, 206) !important;
  text-align: center !important;
  color: white !important;
  font-size: 1rem !important;
  border: 1px solid black !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
}

.tableData {
  text-align: center !important;
  border: 1px solid black !important;
}

.containerTable::-webkit-scrollbar {
  height: 4px !important;
  width: 4px !important;
}

.containerTable::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7) !important;
  border-radius: 10px !important;
}

.containerTable::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255) !important;
}

@media screen and (max-width: 600px) {
  .containerTable {
    width: 90vw !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .containerTable {
    width: 90vw !important;
  }
}

@media screen and (min-width: 855px) and (max-width: 1160px) {
  .containerTable {
    width: 88vw !important;
  }
}
