.card {
  display: block;
  top: 0px;
  position: relative;
  width: 160px !important;
  height: 200px;
  background-color: rgb(224, 236, 243);
  border-radius: 10px;
  padding: 25px;
  margin: 1rem;
  z-index: 0;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  cursor: pointer;

  &:hover {
    transition: all 0.2s ease-out;
    transform: scale(1.02);
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: rgb(30, 131, 232);
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  &:hover:before {
    transform: scale(2.15);
  }
}

.deletedCard {
  background-color: #aa0000;
  color: white;
}

.cardContent {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.card h4 {
  text-transform: uppercase;
  font-size: 1.3rem;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.descriptionData {
  margin-top: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cardDescription {
  font-size: 18px;
  line-height: 1.7rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cardEmail {
  word-wrap: break-word;
  font-size: 0.95rem;
}

.cardActions {
  display: flex;
  gap: 1rem;
  margin-top: 3.2rem;
  width: 100%;
}

.resetIcon {
  height: 25px;
}
