.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.errorHeading {
  text-align: center;
  color: red;
  font-size: 3rem;
}

.imageLogin {
  width: 25rem;
}

@media screen and (max-width: 600px) {
  .imageLogin {
    width: 18rem;
    height: 17rem;
  }
}
