.card {
  background-color: rgb(255, 253, 253);
  margin: 2rem auto !important;
  width: 100% !important;
  max-width: 850px !important;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
}

.card:hover {
  transform: translateY(-5px);
}

.cardContent {
  font-family: "Roboto", sans-serif;
}

.jobTitle {
  font-size: 1.8rem;
  font-weight: 600;
  color: white;
}

.jobType {
  font-size: 1.4rem;
  color: white;
  padding: 0.3rem 0.3rem;
  background-color: #69c;
  margin-top: -0.7px;
}

.salary {
  font-size: 1.3rem;
  font-weight: 600;
  color: white;
}

.jobInfo {
  display: flex;
  justify-content: space-between;
  background-color: #69c;
  padding: 0.2rem;
}

.additionalJobInfo {
  padding-bottom: 2rem;
  margin-top: 0.5rem;
  padding: 0.2rem 0.5rem !important;
  max-height: calc(40vh - 45px);
  overflow: auto;
  word-wrap: break-word;
}

.additionalJobInfo::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.additionalJobInfo::-webkit-scrollbar-thumb {
  /* background-color: rgba(121, 127, 205, 0.7); */
  background-color: #69c;
  border-radius: 10px;
}

.additionalJobInfo::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.additionalJobInfo h3 {
  font-size: 1.2rem !important;
  display: flex !important;
  flex-wrap: wrap;
}

.additionalJobInfo p {
  margin-left: 0.5rem !important;
  margin-top: 0.1rem !important;
  word-wrap: break-word;
}

.skillButton {
  font-size: 0.7rem !important;
  text-transform: none;
  background-color: #3498db !important;
  color: white !important;
  margin: 0.3rem !important;
  margin-top: -0.2rem !important;
}

.description {
  display: flex !important;
  flex-direction: column !important;
}

.applyNowContainer {
  margin-bottom: -15px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.applyButton {
  margin-top: 0.5rem !important;
  background-color: green !important;
}

.applyButton:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.deadlineText {
  text-align: center !important;
  color: red;
  font-size: 1.2rem;
  margin-top: 0.5rem !important;
}

.modalBackground {
  display: table-cell;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.modalBox {
  background-color: white;
  padding: 20px;
  position: relative;
  top: 100px;
  opacity: 0;
  animation: modalFadeIn 0.5s forwards;
  animation-delay: 0.4s;
  text-align: center;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  border-radius: 5px;
}

.messageTitle {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-top: -40px;
  margin-bottom: 5px;
}

.closeIconContainer {
  display: flex;
  justify-content: end;
  margin-top: -20px;
  margin-right: -20px;
  margin-bottom: 20px;
}

.modalContent {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.modalBox p {
  font-size: 1.2rem;
}

.buttons {
  margin: 1rem auto !important;
}

@keyframes modalFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 600px) {
  .card {
    width: 98% !important;
  }

  .jobTitle {
    font-size: 1.6rem !important;
  }

  .jobType {
    font-size: 1.2rem;
  }

  .salary {
    font-size: 1.2rem;
  }

  .additionalJobInfo p {
    margin-left: 0.2 !important;
    word-wrap: break-word;
  }

  .modalBox {
    width: 80%;
    top: 10%;
    left: 5%;
    margin: 0;
  }

  .statusHeader {
    text-align: center;
  }
}
