.confirmationPopup {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 300px;
  padding: 0.5rem;
  height: fit-content;
  overflow: hidden;
}

.confirmationTitle {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: white;
  margin-bottom: 10px;
}

.confirmationIconContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
}

.confirmationIcon {
  color: red !important;
  font-size: 2.5rem !important;
}

.confirmationButtonContainer {
  display: flex;
  justify-content: center;
  gap: 18px;
  padding: 1rem 0;
  width: 100%;
}

.confirmationButton {
  font-size: 3rem !important;
}

.noButton {
  color: red !important;
  font-size: 1.7rem !important;
}

.yesButton {
  color: green !important;
  font-size: 1.7rem !important;
}

@media screen and (max-width: 600px) {
  .confirmationPopup {
    width: 70vw;
    padding: 1rem;
  }
}
