.bulkAttendanceContainer {
  margin: 2px auto !important;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 30%;
}

.bulkAttendanceTitle {
  text-align: center;
  color: #333;
}

.mainBox {
  margin: 2px !important;
  width: 95% !important;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  text-align: start;
}

.inputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.inputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.buttonContainer {
  text-align: center;
  margin: 10px !important;
}

.buttonContainer Button {
  width: 220px !important;
}

.createdTime {
  max-height: 30vh;
  overflow: auto;
  background-color: rgb(210, 228, 249);
  border-radius: 10px;
  position: relative;
}

.createdTime::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.createdTime::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.createdTime::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.createdTimeTitle {
  padding: 0.5rem 0 1rem 0;
  color: #333;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgb(210, 228, 249);
  text-transform: uppercase;
}

.timeStack {
  margin: 0.5rem;
  /* padding: 1rem; */
  text-align: center;
}

.individualBox {
  padding: 10px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* background-color: #f9f9f9; Optional background color */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
}

.data {
  font-size: 1rem;
}

@media screen and (max-width: 600px) {
  .bulkAttendanceContainer {
    width: 85vw;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .bulkAttendanceContainer {
    width: 95vw;
  }

  .tabs {
    margin: 0.5rem auto !important;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .bulkAttendanceContainer {
    width: 85vw;
  }

  .tabs {
    margin: 0.5rem auto !important;
  }
}
