.wrapper {
  margin: 1rem 1rem 1.5rem 4.5rem;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 94vw;
}

.formContainer,
.allocationContainer {
  border-radius: 10px;
  box-sizing: border-box;
  border-radius: 10px;
}

.previousButton {
  cursor: pointer;
  height: 20px !important;
  width: 20px !important;
  color: black;
}

.studentListContainer {
  width: 95%;
  margin: 2em auto;
  border-radius: 10px;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
}

.dropDownParentContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 1rem;
}

.formContainerTitle {
  color: #333;
  text-align: center;
  margin-top: -1.5rem;
  /* margin-bottom: 2rem; */
}

.formContainerDetailsTitle {
  color: #333;
  text-align: center;
  margin-top: 1.5rem;
}

.tableContainerTitle {
  color: #333;
  text-align: center;
  margin: 0.5rem;
}

.tableContainer {
  max-height: 600px !important;
  overflow-y: auto;
  display: block;
  border: 1px solid rgb(177, 177, 177) !important;
}

.tableContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.tableContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.inputGroup {
  flex: 1;
  min-width: 150px;
  margin: 0.5rem;
}

.inputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.inputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  width: 96%;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.inputbox {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(145, 145, 145);
}

.addStudentButton {
  margin: 0.5rem auto !important;
  display: flex !important;
}

.tableHead {
  background-color: rgb(88, 147, 206) !important;
  text-align: center !important;
  color: white !important;
  font-size: 1rem !important;
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
  border-bottom: 1px solid black !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1000 !important;
  text-transform: uppercase;
}

.tableData {
  text-align: center !important;
  border: 1px solid black !important;
}

.selectedRow {
  background-color: transparent;
}

.buttonContainer {
  display: flex !important;
  justify-content: center !important;
  margin: 1rem !important;
}

@media screen and (max-width: 600px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }

  .formContainer,
  .allocationContainer,
  .studentListContainer {
    width: 90vw;
  }

  .dropDownParentContainer {
    flex-direction: column;
    gap: 0.5rem;
  }

  .formContainerTitle {
    margin-top: -1rem;
  }

  .formContainerDetailsTitle {
    margin-top: 1rem;
    font-size: 1.1rem;
  }

  .searchBar {
    width: 95% !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .wrapper {
    margin: 1rem auto;
    width: 95vw;
    display: flex;
    flex-direction: column;
  }

  .dropDownParentContainer {
    flex-direction: column;
  }

  .formContainer,
  .allocationContainer,
  .studentListContainer {
    width: 90vw;
  }

  .formContainerDetailsTitle {
    margin-top: 1rem;
  }

  .searchBar {
    width: 97% !important;
  }
}

@media screen and (min-width: 855px) and (max-width: 1160px) {
  .wrapper {
    width: 90vw;
    display: flex;
    flex-direction: column;
  }

  .dropDownParentContainer {
    flex-direction: column;
  }

  .searchBar {
    width: 98% !important;
  }

  .formContainerDetailsTitle {
    margin-top: 1rem;
  }
}
