.scrollableContent {
  min-height: 400px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #69c;
}

.navbar {
  background-color: #69c;
  color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeIcon {
  cursor: pointer;
}

.navbarHeader {
  margin: 0;
  font-size: 1.5rem;
}

.headerContents {
  display: flex;
  align-items: center;
}

.plusIcon {
  font-size: 19px;
  margin-top: -2px;
  margin-right: 5px;
}

.addSlotButton {
  margin-right: 0.5rem !important;
  color: #ffffff !important;
  border: none !important;
  font-weight: bold !important;
}

.addSlotButton:hover {
  background-color: #1b4a7a !important;
}

.textFieldContainer {
  display: flex;
  width: 100%;
}

.inputGroup {
  margin: 0.1rem auto !important;
  width: 100%;
}

.inputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.inputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  background-color: #f1f2f5;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  width: 90%;
}

.saveButton {
  margin-left: 20px !important;
  cursor: pointer !important;
  margin-top: 20px !important;
  color: rgb(7, 87, 168) !important;
  font-size: 40px !important;
}

@media screen and (max-width: 600px) {
  .textFieldContainer {
    flex-direction: column !important;
    gap: 0.5rem;
  }

  .inputGroup input {
    width: 70vw;
  }

  .saveButton {
    margin: 0.5rem auto !important;
  }
}
