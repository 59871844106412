.jobApplicationContainer {
  margin: 1rem 1rem 1.5rem 4.5rem;
  padding: 0.5rem 0;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 94vw;
}

.title {
  text-align: center;
  color: #333;
}

.closeIconContainer {
  margin-top: -45px;
}

.dropDownParentContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem auto;
  width: 60%;
}

.dropDownContainer {
  flex: 1;
  min-width: 150px;
  margin: 0.5rem;
  text-align: center !important;
}

.dropDownLabel {
  font-weight: bold;
  font-size: 1rem;
  color: #494949;
}

.searchLabel {
  font-weight: bold;
  font-size: 1rem;
  color: #494949;
  display: block;
}

.searchBar {
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 92%;
}

.applicationCard {
  text-align: center;
  box-sizing: border-box;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  margin: 2rem auto !important;
  width: 100% !important;
  max-width: 750px !important;
  background-color: rgb(236, 236, 236);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
}

.applicationCard:hover {
  transform: translateY(-5px);
}

.candidateName {
  color: white;
  background-color: #69c;
}

.additionalDetails {
  margin: 0.1rem auto !important;
  margin-bottom: 1rem !important;
}

.additionalDetails h3 {
  display: flex !important;
  flex-wrap: wrap;
  font-family: "Roboto", sans-serif;
  font-size: 1.3rem;
  color: #333333;
}

.additionalDetails p {
  font-size: 1.3rem;
  font-family: "Roboto", sans-serif;
  margin-left: 0.5rem !important;
  word-wrap: break-word;
}

.buttonContainer {
  display: flex;
  width: 80%;
  margin: 0.6rem auto !important;
  justify-content: space-around;
  /* padding: 0.5rem; */
  border-radius: 10px;
}

.buttonContainer Button {
  border: none !important;
}

.viewProfileButton {
  background-color: rgb(91, 43, 91) !important;
}

.applicationStatus {
  padding: 0.2rem;
}

.pending {
  background-color: #f7c100 !important;
  color: black;
}

.approved {
  background-color: #46923c !important;
  color: white;
}

.rejected {
  background-color: #e74c3c !important;
  color: white;
}

.noApplicationsText {
  text-align: center;
  color: red;
  font-size: 1.2rem;
  margin-top: 2rem !important;
}

.messageIcon {
  font-size: 2rem !important;
  color: #69c !important;
  cursor: pointer;
  margin: 0.3rem auto !important;
}

@media screen and (max-width: 600px) {
  .jobApplicationContainer {
    margin: 1rem auto;
    flex-direction: column;
  }

  .dropDownParentContainer {
    flex-direction: column;
    width: 80%;
  }

  .buttonContainer {
    flex-wrap: wrap;
    width: 100%;
  }

  .buttonContainer Button {
    margin: 5px;
    width: 150px !important;
  }

  .applicationCard {
    width: 95% !important;
  }

  .candidateName {
    font-size: 1.5rem;
  }

  .aboutIcon {
    width: 9vw;
    margin: 0.3rem 0;
    top: 50%;
    left: 45%;
    background-color: #69c;
  }

  .applicationStatus {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .jobApplicationContainer {
    margin: 1rem auto;
    flex-direction: column;
  }

  .dropDownParentContainer {
    /* flex-direction: column; */
    width: 95%;
  }

  .buttonContainer {
    width: 100%;
  }

  .buttonContainer Button {
    margin: 5px;
    width: 150px !important;
  }

  .applicationCard {
    width: 95% !important;
  }

  .candidateName {
    font-size: 1.5rem;
  }

  .aboutIcon {
    width: 9vw;
    margin: 0.3rem 0;
    top: 50%;
    left: 45%;
    background-color: #69c;
  }

  .applicationStatus {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .jobApplicationContainer {
    flex-direction: column;
    width: 90vw;
  }

  .dropDownParentContainer {
    /* flex-direction: column; */
    width: 95%;
  }

  .buttonContainer {
    /* flex-wrap: wrap; */
    width: 100%;
  }

  .buttonContainer Button {
    margin: 5px;
    width: 150px !important;
  }

  .applicationCard {
    width: 95% !important;
  }

  .candidateName {
    font-size: 1.5rem;
  }

  .aboutIcon {
    width: 9vw;
    margin: 0.3rem 0;
    top: 50%;
    left: 45%;
    background-color: #69c;
  }

  .applicationStatus {
    font-size: 1.3rem;
  }
}
