.searchLabel {
  font-weight: bold;
  font-size: 1rem;
  color: #494949;
  display: block;
}

.searchBar {
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 92%;
}

.dropDownParentContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem auto;
}

.dropDownContainer {
  flex: 1;
  min-width: 150px;
  margin: 0.5rem;
}

.dropDownLabel {
  font-weight: bold;
  font-size: 1rem;
  color: #494949;
}

@media screen and (max-width: 600px) {
  .dropDownParentContainer {
    flex-direction: column;
    gap: 1.2rem;
    padding: 0 0.5rem;
  }

  .dropDownContainer {
    max-width: 100%;
    margin: 0;
  }

  .searchBar {
    width: 93%;
  }
}
