.resumeHeader {
  color: rgb(40, 44, 63);
}

.icon {
  height: 35px;
  margin-top: -10px;
  margin-left: 5px;
  vertical-align: middle;
}

.viewResumeButton {
  margin-top: 1rem !important;
  background-color: green !important;
}

.viewIcon {
  margin-right: 5px !important;
  height: 22px !important;
}
