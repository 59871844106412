.wrapper {
  margin: 1rem 1rem 1.5rem 4.5rem;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 94vw;
}

.communityHeader {
  text-align: center;
  color: #333;
}

.searchBarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 0;
}

.searchLabel {
  font-weight: bold;
  color: #494949;
  display: block;
}

.searchBar {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 25%;
}

.searchMemberSpanTag {
  color: red;
}

.dropDownParentContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  gap: 1rem;
}

.dropDownContainer {
  flex: 1;
  min-width: 150px;
  margin: 0.5rem;
}

.dropDownLabel {
  font-weight: bold;
  font-size: 1rem;
  color: #494949;
}

.noData {
  text-align: center !important;
  color: red;
  font-size: 1.1rem;
  margin-top: 1rem;
}

.modalBackground {
  display: table-cell;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.modal {
  background-color: white;
  padding: 20px 5px 20px 20px;
  position: relative;
  top: 100px;
  opacity: 0;
  animation: modalFadeIn 0.5s forwards;
  animation-delay: 0.4s;
  text-align: center;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  border-radius: 5px;
}

.closeIconContainer {
  display: flex !important;
  justify-content: end !important;
  margin-top: -20px;
  margin-right: -5px;
  margin-bottom: 10px;
}

.formScrollableHeader {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-top: -30px;
  margin-bottom: 15px;
}

.formScrollable {
  max-height: calc(75vh - 25px);
  overflow: auto;
  padding-right: 16px;
}

.formScrollable::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.formScrollable::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.formScrollable::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.loginGroup {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  text-align: start;
}

.loginGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.loginGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.error {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

.disabled {
  background-color: #f6f6f6 !important;
  color: #a0a0a0 !important;
}

.submitButtonContainer {
  margin: 1rem auto !important;
}

@keyframes modalFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }

  .dropDownParentContainer {
    flex-direction: column;
    gap: 0.5rem;
  }

  .dropDownContainer {
    max-width: 100%;
  }

  .searchMemberSpanTag {
    font-size: 14px;
  }

  .searchBar {
    width: 85%;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }

  .searchBar {
    width: 60%;
  }

  .dropDownParentContainer {
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .wrapper {
    width: 90vw;
    display: flex;
    flex-direction: column;
  }

  .searchBar {
    width: 50vw;
  }

  .dropDownParentContainer {
    flex-direction: column;
    gap: 0.5rem;
  }
}
