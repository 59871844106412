.createGroupContainer {
  position: fixed;
  bottom: 10px;
  right: 20px;
  background-color: #ffffff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 15px;
  height: 500px;
  width: 300px;
  padding: 0;
  overflow-y: auto;
  z-index: 100000000;
}

.closeIconContainer {
  display: flex;
  justify-content: space-between;
  background-color: #69c;
  color: white !important;
}

.closeIconContainer {
  padding-right: 0.3rem;
}

.createGroupContainerTitle {
  padding: 0.3rem 0.5rem;
}

.createGroupButton,
.closeIcon {
  color: white !important;
  font-size: 1.4rem !important;
  margin-left: 5px;
  cursor: pointer;
  padding: 0.5rem 0.2rem;
}

.loadingText {
  text-align: center;
  color: red;
  margin-top: 1rem;
}

.searchBar {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #ccc;
  padding: 10px;
}

.searchBar input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 5px;
}

.searchIcon {
  cursor: pointer;
  margin-left: 5px;
}

.chatGroups {
  overflow-y: auto;
  max-height: calc(100% - 110px);
  /* padding-right: 5px; */
}

.groupListContainer {
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.groupListContainer:hover {
  background-color: #f0f0f0;
}

.chatGroups::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.chatGroups::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.chatGroups::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.createNewGroupContainer {
  position: fixed;
  bottom: 10px;
  right: 20px;
  background-color: #ffffff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 15px;
  height: 500px;
  width: 300px;
  padding: 0;
  overflow-y: auto;
}

.closeIconCreateNewGroupContainer {
  display: flex;
  justify-content: space-between;
  background-color: #69c;
  color: white !important;
}

.createNewGroupContainerTitle {
  padding: 0.3rem 0.5rem;
}

.closeButton {
  color: white !important;
  font-size: 1.4rem !important;
  margin-left: 5px;
  cursor: pointer;
  padding: 0.5rem;
}

.inputGroupNameContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px auto;
  width: 100%;
}

.inputGroupName {
  outline: none;
  font-size: 1rem;
  padding: 5px;
  margin: 2px auto;
  border: 1px solid #ccc;
  border-radius: 7px;
  width: 80%;
}

.createNewGroupMembersList {
  overflow-y: auto;
  max-height: calc(100% - 60px);
  /* padding-right: 5px; */
}

.createNewGroupMembersListTitle {
  text-align: center;
  padding: 0.3rem;
  background-color: rgb(105, 105, 177);
  color: #ffffff;
}

.members {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  padding: 0.3rem;
}

.createNewGroupMembersList::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.createNewGroupMembersList::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.createNewGroupMembersList::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.confirmGroupButtonContainer {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.confirmGroupButton {
  font-size: 0.7rem !important;
}

.errorMessage {
  text-align: center;
  color: red;
  margin: 5px 0;
  font-size: 1rem;
}

@media (max-width: 600px) {
  .createGroupContainer {
    bottom: 0;
    right: 10px;
  }
}
