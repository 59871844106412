.container {
  margin: 1rem auto;
  width: 35%;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
}

.tabContainer {
  width: 100%;
  border-radius: 10px;
  margin: 1px auto;
  display: flex;
}

.titleContainer {
  text-align: center;
  color: #333;
}

.tabSection {
  width: 100%;
  border-radius: 10px 10px 0 0;
  background-color: #69c;
  display: flex;
  text-align: center;
  justify-content: center;
  color: white;
  align-items: center;
  padding: 0.2rem 0;
}

.tabOption {
  font-size: 1rem !important;
}

@media screen and (max-width: 600px) {
  .titleContainer {
    font-size: 1.6rem;
  }

  .container {
    width: 95vw;
  }

  .tabContainer {
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .container {
    width: 75vw;
    margin-top: 3rem !important;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .container {
    width: 60vw;
    margin-left: 13rem !important;
    margin-top: 3rem !important;
  }
}
