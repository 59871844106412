.wrapper {
  margin: 1rem 1rem 1.5rem 4.5rem;
  padding: 0.5rem 0;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 94vw;
}

.titleContainer {
  text-align: center;
  color: #333;
  padding: 5px;
  margin-bottom: 20px;
}

.timetable {
  padding: 0.5rem;
  margin: 0 0.5rem;
}

.lectureContainer {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  margin: 0.5rem auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4) !important;
  background-color: #fcfcfc;
  border-radius: 15px;
  height: 200px;
  padding: 0 0.5rem;
}

.dayTitleContainer {
  height: 11rem;
  width: 19%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid rgb(103, 103, 103);
  margin: 0 0.5rem;
}

.dayTitle {
  text-align: center;
}

.cardContainer {
  display: flex !important;
  flex-direction: row !important;
  gap: 20px !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;
  height: 200px !important;
  width: 100% !important;
}

.timetableCard {
  position: relative;
  width: 100%;
  max-width: 350px;
  margin: 10px;
  border-radius: 15px !important;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.9) !important;
  background: linear-gradient(to right, #614385, #516395);
  overflow: hidden;
  transition: transform 0.4s cubic-bezier(0.17, 0.67, 0.5, 1.03);
  cursor: pointer !important;
  flex-shrink: 0;
  height: 170px;
}

.cardContent {
  padding: 14px;
  background: linear-gradient(to right, #614385, #516395);
  color: #ffffff !important;
  transition: opacity 0.4s ease-in-out;
  cursor: pointer !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 15px !important;
}

.subjectName {
  font-weight: bold;
  /* color: #c32361; */
  color: #ffffff !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cardDetails {
  opacity: 0;
  transition: opacity 0.4s ease-in-out 0.15s;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #d1cece !important;
}

.timetableCard:hover .cardDetails {
  opacity: 1;
  color: #d1cece !important;
}

.time {
  color: #d1cece !important;
}

.noLectures {
  text-align: center;
  margin-top: 5.6rem;
  margin-left: 18rem;
}

.noData {
  text-align: center;
  color: red;
  font-size: 1.2rem;
  margin-bottom: -0.5rem;
}

.cardContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.cardContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.cardContainer::-webkit-scrollbar-track {
  background-color: transparent;
}

@media screen and (max-width: 600px) {
  .wrapper {
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
  }

  .titleContainer {
    font-size: 1.6rem;
  }

  .lectureContainer {
    flex-direction: column;
    border-radius: 10px;
    height: auto;
    width: 87vw;
    margin: 0.5rem auto;
  }

  .cardContainer {
    flex-direction: column !important;
  }

  .cardDetails {
    opacity: 1;
    color: #d1cece !important;
    display: block;
  }

  /* .timetableCard {
    width: 90%;
  } */

  .dayTitleContainer {
    height: 2rem;
    border: none;
    width: 100%;
    display: block;
    margin: 0.5rem;
  }

  .noLectures {
    margin-top: 2.5rem;
    margin-left: 0;
  }

  .timetableCard:hover .cardDetails {
    opacity: 1;
    color: #d1cece !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }

  .scheduleContainer {
    margin: 0.5rem auto;
    flex-direction: column;
  }

  .scheduleTitle {
    font-size: 1.6rem;
  }

  .lectureContainer {
    flex-direction: column;
    border-radius: 10px;
    height: auto;
    width: 87vw;
    margin: 0.5rem auto;
  }

  .cardContainer {
    flex-direction: column !important;
  }

  .cardDetails {
    opacity: 1;
    color: #d1cece !important;
    display: block;
  }

  .noLectures {
    margin-top: 2.5rem;
    margin-left: 0;
  }

  .addLectureButton {
    margin-top: 5px !important;
  }

  .modal {
    width: 80vw;
  }

  .dayTitleContainer {
    height: 2rem;
    border: none;
    width: 100%;
    display: block;
    margin: 0.5rem;
  }

  .timetableCard {
    max-width: 200px;
  }

  .timetableCard:hover .cardDetails {
    opacity: 1;
    color: #d1cece !important;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .wrapper {
    width: 90vw;
    display: flex;
    flex-direction: column;
  }

  .scheduleContainer {
    margin: 0.5rem auto;
    flex-direction: column;
  }

  .cardContainer {
    flex-direction: column !important;
  }

  .dayTitle {
    font-size: 1.2rem;
  }

  .timetableCard {
    max-width: 200px;
  }

  .cardDetails {
    opacity: 1;
    color: #d1cece !important;
    display: block;
  }

  .noLectures {
    margin-left: 0;
  }
}
