.wrapper {
  margin: 1rem 1rem 1.5rem 4.5rem;
  padding: 0.5rem 0;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 94vw;
}

.title {
  text-align: center;
  color: #333;
}

.tableContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.tableContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.inputParentContainer {
  margin: 1.5rem 0;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btnContainer {
  height: 40px !important;
  margin-top: 1rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.btnContainer Button {
  width: 180px !important;
}

.searchLabel {
  font-weight: bold;
  font-size: 1rem;
  color: #494949;
}

.searchDepartment {
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 20%;
}

.noData {
  margin-top: 2rem !important;
  margin-bottom: 0.2rem !important;
  text-align: center !important;
  color: red;
  font-size: 1.1rem;
}

.updateDepartmentContainer {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  padding: 20px 5px 20px 20px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 30%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Arial", sans-serif;
  text-align: center;
  overflow: hidden;
}

.updateDepartmentScrollable {
  max-height: 70vh;
  overflow: auto;
  padding-right: 16px;
}

.closeIconContainer {
  display: flex !important;
  justify-content: end !important;
  margin-top: -20px;
  margin-right: -5px;
  margin-bottom: 10px;
}

.error {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

.updateDepartmentHeader {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-top: -30px;
  margin-bottom: 15px;
}

.courseInputGroup {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  text-align: start;
}

.courseInputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.courseInputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.span {
  font-size: 14px;
  color: rgb(228, 55, 55);
}

.updateDepartmentScrollable::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.updateDepartmentScrollable::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.updateDepartmentScrollable::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.modal {
  background: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 600px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }

  .addDepartmentButton {
    width: 50% !important;
    margin: 5px auto !important;
  }

  .searchDepartment {
    width: 70%;
  }

  .courseInputGroup label {
    font-size: 16px;
  }

  .tableContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 90vw;
    padding: 2px;
    margin-top: -10px;
    overflow-x: scroll;
  }

  .updateDepartmentContainer {
    width: 95vw;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }

  .searchDepartment {
    width: 40%;
  }

  .updateDepartmentContainer {
    width: 75vw;
  }

  .addDepartmentButton {
    width: 50% !important;
    margin: 5px auto !important;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .wrapper {
    width: 90vw;
    display: flex;
    flex-direction: column;
  }

  .searchDepartment {
    width: 30%;
  }

  .updateDepartmentContainer {
    width: 60vw;
  }
}
