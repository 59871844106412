.wrapper {
  margin: 1rem 1rem 1.5rem 4.5rem;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 94vw;
}

.title {
  text-align: center;
  color: #333;
  margin-top: -0.5rem;
}

.closeIconContainer {
  margin-top: -10px;
}

.studentFilterContainer {
  margin: -2rem auto;
  padding: 1rem 0;
}

.inputParentContainer {
  width: 25%;
  margin: 1rem auto;
  justify-content: center;
  text-align: center;
}

.inputContainer {
  display: flex;
}

.dropDownParentContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  gap: 1rem;
}

.dropDownContainer {
  flex: 1;
  min-width: 150px;
  margin: 0.5rem;
}

.inputArea {
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 92%;
}

.selectOption {
  height: 40px;
  /* padding: 8px; */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.addButtonContainer {
  width: 10% !important;
  margin-top: 1.7rem !important;
}

.addButtonContainer .btn {
  margin-left: 0.5rem !important;
  width: 70% !important;
}

.criteriaTitle {
  text-align: center;
  margin-top: 1rem;
}

.parentCardContainer {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  /* margin: 20px; */
  padding: 1rem;
  gap: 2rem !important;
  /* background-color: rgb(215, 225, 236); */
}

.selectedSkillCriteria {
  background-color: #dddddd;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  width: 50%;
  margin-bottom: 20px;
}

.selectedEducationCriteria {
  background-color: #dddddd;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  width: 50%;
  margin-bottom: 20px;
}

.skillCriteriaTitle,
.eduCriteriaTitle {
  text-align: center;
}

.cardContent {
  text-align: center;
  margin-top: 1rem;
}

.educationItem {
  display: flex;
  justify-content: flex-start;
  margin: 0.5rem auto;
  width: 90%;
}

.educationItem p {
  margin: 7px 10px;
  word-wrap: break-word;
  flex: 2;
  white-space: normal;
  text-align: left;
}

.educationItem span {
  margin-top: 8px;
  font-weight: bolder;
}

.deleteButton {
  background-color: #e74c3c !important;
  color: white;
  border: none !important;
  cursor: pointer;
  /* width: 20%; */
  display: block;
}

.deleteButton:hover {
  background-color: #c0392b;
}

.buttonContainer {
  padding: 1rem;
  text-align: center !important;
  gap: 1rem !important;
}

.buttonContainer Button {
  margin: 0.5rem !important;
}

.exportButton {
  background-color: green !important;
}

.studentListContainer {
  width: 95%;
  margin: 2em auto;
  border-radius: 10px;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
}

.tableContainerTitle {
  color: #333;
  text-align: center;
  margin: 0.5rem;
}

.tableContainer {
  max-height: 600px !important;
  width: 90% !important;
  margin: 1rem auto !important;
  overflow-y: auto;
  display: block;
  border: 1px solid rgb(177, 177, 177) !important;
}

.tableContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.tableContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.tableHead {
  background-color: rgb(88, 147, 206) !important;
  text-align: center !important;
  color: white !important;
  font-size: 1rem !important;
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
  border-bottom: 1px solid black !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1000 !important;
  text-transform: uppercase;
}

.tableData {
  text-align: center !important;
  border: 1px solid black !important;
}

@media screen and (max-width: 600px) {
  .wrapper {
    margin: 1rem auto;
    width: 90vw;
  }

  .parentCardContainer {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 1.4rem;
    width: 80%;
    margin-top: -3.5rem !important;
    margin: 0.1px auto;
  }

  .closeIconContainer {
    /* margin-top: -3.1rem !important; */
    margin-left: -10px;
  }

  .dropDownParentContainer {
    flex-direction: column;
    gap: 0.5rem;
  }

  .studentFilterContainer {
    margin-top: 1rem;
  }

  .dropDownContainer {
    width: 81vw;
  }

  .selectedCriteria {
    margin: 1rem auto !important;
  }

  .inputParentContainer {
    width: 70%;
  }

  .selectedSkillCriteria {
    width: 100%;
  }

  .selectedEducationCriteria {
    width: 100%;
  }

  .educationItem {
    flex-direction: column;
  }

  .educationItem p {
    text-align: center;
  }

  .addButtonContainer {
    margin: 0.5rem auto !important;
    text-align: center !important;
    width: 60% !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .wrapper {
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
  }

  .dropDownParentContainer {
    flex-direction: column;
    gap: 0.5rem;
  }

  .title {
    font-size: 1.6rem;
    width: 80%;
    margin-top: -3.5rem !important;
    margin: 0.1px auto;
  }

  .closeIconContainer {
    /* margin-top: -3.1rem !important; */
    margin-left: -14px;
  }

  .inputArea {
    width: 86vw;
  }

  .studentFilterContainer {
    margin-top: 1rem;
  }

  .dropDownContainer {
    max-width: 100%;
  }

  .selectedCriteria {
    margin: 1rem auto !important;
  }

  .inputParentContainer {
    width: 50%;
  }

  .selectedSkillCriteria {
    width: 100%;
  }

  .selectedEducationCriteria {
    width: 100%;
  }

  .educationItem {
    flex-direction: column;
  }

  .educationItem p {
    text-align: center;
  }

  .addButtonContainer {
    margin: 0.5rem auto !important;
    text-align: center !important;
    width: 40% !important;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    width: 90vw;
  }

  .dropDownParentContainer {
    flex-direction: column;
    gap: 0.5rem;
  }

  .title {
    font-size: 1.6rem;
    width: 90%;
    margin-top: -3.5rem !important;
    margin: 0.1px auto;
  }

  .closeIconContainer {
    margin-left: -10px;
  }

  .inputArea {
    width: 84vw;
  }

  .studentFilterContainer {
    margin-top: 1rem;
  }

  .selectOption {
    width: 100% !important;
  }

  .dropDownContainer {
    max-width: 100%;
  }

  .selectedCriteria {
    margin: 1rem auto !important;
  }

  .inputParentContainer {
    width: 40%;
  }

  .selectedSkillCriteria {
    width: 100%;
  }

  .selectedEducationCriteria {
    width: 100%;
  }

  .educationItem {
    flex-direction: column;
  }

  .educationItem p {
    text-align: center;
  }

  .addButtonContainer {
    margin: 0.5rem auto !important;
    text-align: center !important;
    width: 40% !important;
  }

  .deleteButton {
    width: 30% !important;
    margin: 0.5rem auto !important;
  }

  .selectedSkillCriteria,
  .selectedEducationCriteria {
    width: 70% !important;
  }
}
