.userListPopup {
  position: fixed;
  z-index: 2;
  bottom: 10px;
  right: 630px;
  width: 250px;
  height: 425px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 25px;
}

.buttons {
  position: absolute;
  bottom: 0;
}

.userListPopup h3 {
  margin-bottom: 10px;
  padding: 15px;
  background-color: #1976d2;
  color: white;
  border-radius: 5px 5px 0 0;
  display: block;
  font-size: 1.17em;
  margin-block-start: 0em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

.userListPopup ul {
  list-style-type: none;
  height: 420px;
  padding: 0;
  max-height: 250px;
  overflow-y: auto;
}

.userListPopup ul li {
  margin-bottom: 10px;
}

.userListPopup label {
  display: inline-block;
  cursor: pointer;
}

.userListPopup button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #1976d2;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.userListPopup button:hover {
  background-color: #1976d2;
}

@media (max-width: 600px) {
  .userListPopup {
    right: 325px;
    width: 300px;
  }
}
