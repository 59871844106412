.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 700px;
  height: 650px;
  overflow: auto;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modalIframe {
  width: 100%;
  height: 600px;
  border: none;
}

.closeIconContainer {
  display: flex !important;
  justify-content: end !important;
  margin-top: -15px;
  margin-right: -15px;
}

/* Responsive styles */
@media (max-width: 600px) {
  .modalContent {
    width: 100%;
    height: 100%;
    max-width: none;
    border-radius: 0;
    padding: 10px;
  }

  .modalIframe {
    width: 100%;
    height: 700;
  }

  .closeButton {
    display: none;
    visibility: hidden;
  }

  .closeIconContainer {
    display: flex !important;
    justify-content: end !important;
    margin-top: 0;
  }
}
