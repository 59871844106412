.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  box-sizing: border-box;
  
}

.resetPasswordContainer {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  max-width: 330px;
  width: 100%;
  box-sizing: border-box;
  margin: 2px;
}

.resetPasswordContainer h2 {
  text-align: start;
  color: #333;
}

.inputGroup {
  margin: 15px 0;
}

.inputGroup label {
  font-weight: bold;
  font-size: 16px;
  color: #494949;
  margin-bottom: 5px;
}

.inputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.submitButton {
  border-radius: 5px;
  padding: 10px;
  margin-top: 5px !important;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  height: 37px;
}

.error {
  color: red;
  font-size: 0.875rem;
}

@media screen and (max-width: 600px) {
  .main {
    margin-left: 25px;
    width: 95%;
  }

  .resetPasswordContainer {
    max-width: 400px;
    width: 100%;
  }

  .inputGroup input {
    padding: 12px;
    border-radius: 4px;
    font-size: 1rem;
  }
}
