.wrapper {
  margin: 1rem 1rem 1.5rem 4.5rem;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 94vw;
}

.title {
  text-align: center;
  color: #333;
}

.table {
  border: 1px solid black !important;
}

.inputParentContainer {
  margin: 1.5rem 0;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addCompanyButtonContainer {
  height: 40px !important;
  margin-top: 1rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.searchLabel {
  font-weight: bold;
  font-size: 1rem;
  color: #494949;
}

.searchCompany {
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 20%;
}

.noData {
  margin-top: 2rem !important;
  margin-bottom: 0.2rem !important;
  text-align: center !important;
  color: red;
  font-size: 1.1rem;
}

.updateCompanyContainer {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  padding: 20px 5px 20px 20px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 30%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Arial", sans-serif;
  text-align: center;
  overflow: hidden;
}

.updateCompanyScrollable {
  max-height: calc(65vh - 25px);
  overflow: auto;
  padding-right: 16px;
}

.closeIconContainer {
  display: flex !important;
  justify-content: end !important;
  margin-top: -20px;
  margin-right: -5px;
  margin-bottom: 10px;
}

.error {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

.updateCompanyHeader {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-top: -30px;
  margin-bottom: 10px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  text-align: start;
}

.inputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.inputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.span {
  font-size: 14px;
  color: rgb(228, 55, 55);
}

.modal {
  background: rgba(0, 0, 0, 0.5);
}

.updateCompanyScrollable::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.updateCompanyScrollable::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.updateCompanyScrollable::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.disabled {
  background-color: #f6f6f6 !important;
  color: #a0a0a0 !important;
}

@media screen and (max-width: 600px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }

  .updateCompanyContainer {
    width: 95vw;
  }

  .addCompanyButton {
    width: 50% !important;
    margin: 5px auto !important;
  }

  .searchCompany {
    width: 70%;
  }

  .inputGroup label {
    font-size: 16px;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }

  .updateCompanyContainer {
    width: 75vw;
  }

  .addCompanyButton {
    width: 25% !important;
    margin: 5px auto !important;
  }

  .searchCompany {
    width: 40%;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .wrapper {
    width: 90vw;
    display: flex;
    flex-direction: column;
  }

  .searchCompany {
    width: 40%;
  }

  .updateCompanyContainer {
    width: 60vw;
  }
}
