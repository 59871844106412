.viewPracticalBatchContainer {
  margin: 1rem 1rem 1.5rem 4.5rem;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 94vw;
}

.batchContainerTitle {
  color: #333;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: -1rem;
}

.previousButton {
  cursor: pointer;
  height: 20px !important;
  width: 20px !important;
  color: black;
}

.tableHead {
  background-color: rgb(88, 147, 206) !important;
  text-align: center !important;
  color: white !important;
  font-size: 1rem !important;
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
  border-bottom: 1px solid black !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1000 !important;
  text-transform: uppercase;
}

.tableData {
  text-align: center !important;
  border: 1px solid black !important;
}

.containerTable {
  margin: 1rem auto !important;
  width: 50% !important;
  max-height: 600px !important;
}

.containerTable::-webkit-scrollbar {
  height: 4px !important;
  width: 4px !important;
}

.containerTable::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7) !important;
  border-radius: 10px !important;
}

.containerTable::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255) !important;
}

.actionButtons {
  display: flex;
  margin: 0.2px auto !important;
  justify-content: center;
}

.editIcon,
.deleteIcon {
  cursor: pointer;
}

.dialogContainer::-webkit-scrollbar {
  height: 4px !important;
  width: 4px !important;
}

.dialogContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7) !important;
  border-radius: 10px !important;
}

.dialogContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255) !important;
}

.dialogTitle {
  text-align: center;
  margin-top: -1rem;
}

.closeIconContainer {
  display: flex;
  justify-content: end;
  margin-top: 5px;
  margin-right: 5px;
}

.inputGroup {
  margin: 0.1rem auto !important;
  width: 80%;
}

.inputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
  display: block;
}

.inputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  background-color: #f1f2f5;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  width: 90%;
}

.title {
  text-align: center;
  padding: 0.5rem;
  color: red;
}

.moveIcon {
  font-size: 30px !important;
  height: 30px !important;
}

.dialogContainerTable {
  height: 400px;
}

.dialogContainerTable::-webkit-scrollbar {
  height: 4px !important;
  width: 4px !important;
}

.dialogContainerTable::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7) !important;
  border-radius: 10px !important;
}

.dialogContainerTable::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255) !important;
}

.updateBatchDialogButton {
  margin: 0.5px auto !important;
}

.titleMoveStudent {
  text-align: center;
  margin-bottom: 1rem;
}

.confirmationButtonContainer {
  display: flex;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.confirmationButton {
  font-size: 3rem !important;
}

.noButton {
  color: red !important;
  font-size: 1.7rem !important;
}

.yesButton {
  color: green !important;
  font-size: 1.7rem !important;
}

@media screen and (max-width: 600px) {
  .viewPracticalBatchContainer {
    flex-direction: column;
    margin: 1rem auto;
  }

  .actionButtons {
    width: 100%;
  }

  .containerTable {
    width: 100% !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .viewPracticalBatchContainer {
    margin: 1rem auto;
    width: 95vw;
    display: flex;
    flex-direction: column;
  }

  .containerTable {
    width: 100% !important;
  }
}

@media screen and (min-width: 855px) and (max-width: 1160px) {
  .viewPracticalBatchContainer {
    width: 90vw;
    display: flex;
    flex-direction: column;
  }

  .containerTable {
    width: 100% !important;
  }
}
