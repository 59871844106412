.header {
  text-align: center;
}

.registerInstituteContainer {
  margin: 2rem auto;
  width: 33%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  padding: 20px 5px 20px 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  font-family: "Arial", sans-serif;
}

.closeIconContainer {
  display: flex !important;
  justify-content: end !important;
  margin-top: -20px;
  margin-right: -5px;
  margin-bottom: 10px;
}

.registerInstituteContainerHeader {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: -30px;
}

.registerFormScrollable {
  max-height: 70vh;
  overflow: auto;
  padding-right: 16px;
}

.registerFormScrollable::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.registerFormScrollable::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.registerFormScrollable::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.registerInputGroup {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  text-align: start;
}

.registerInputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.registerInputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.toggleSwitchContainer {
  text-align: center;
  margin: 0.5rem auto;
}

.submitButtonContainer {
  text-align: center;
}

.error {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

.span {
  font-size: 14px;
  color: rgb(228, 55, 55);
}

@media screen and (max-width: 600px) {
  .registerInstituteContainer {
    width: 95vw;
  }

  .registerInstituteContainerHeader {
    display: flex;
    justify-content: center;
    width: 70%;
    margin: -30px auto !important;
    margin-bottom: 0.5rem !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .registerInstituteContainer {
    width: 75vw;
    margin-top: 3rem !important;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .registerInstituteContainer {
    width: 60vw;
    margin-top: 3rem !important;
  }
}
