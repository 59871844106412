.headingSummary {
  line-height: 1.9rem;
  color: rgb(40, 44, 63);
}

.horizontalRow {
  width: 100%;
  margin-top: 10px;
}

.yourSummary {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  padding: 2rem;
  gap: 2rem;
  margin: 25px 0;
  width: 98.5%;
  max-width: 1420px;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 2rem;
}

.closeIconContainer {
  margin-top: -7px;
  margin-right: -27px;
}

.summaryHeader {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  margin-top: -1.5rem;
}

.summaryHeader span {
  font-weight: bold;
}

.summaryContent p {
  font-family: Lato, Roboto, sans-serif;
  font-size: 1.1rem;
}

.summaryContent span {
  font-weight: bold;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
}

.noData {
  color: rgb(228, 55, 55);
  font-size: 1rem;
  margin-top: 5px;
}

.addButton {
  margin-top: 1rem !important;
  background-color: green !important;
}

.addButton:hover {
  background-color: rgb(0, 65, 0) !important;
}

.icon {
  height: 35px;
  margin-top: -10px;
  margin-left: 5px;
  vertical-align: middle;
}

.aboutIcon {
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
  margin-top: -5px;
}

@media screen and (max-width: 600px) {
  .yourSummary {
    width: 92vw;
  }

  .summaryContent {
    max-height: calc(45vh - 30px);
    overflow: auto;
    padding-right: 30px;
    width: 100%;
    word-wrap: break-word;
  }

  .summaryContent::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  .summaryContent::-webkit-scrollbar-thumb {
    background-color: rgba(191, 193, 217, 0.7);
    border-radius: 10px;
  }

  .summaryContent::-webkit-scrollbar-track {
    background-color: rgb(255, 255, 255);
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .yourSummary {
    width: 93vw;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .yourSummary {
    width: 89vw;
  }
}
