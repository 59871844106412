.messageContainer {
  position: fixed;
  bottom: 10px;
  right: 20px;
  background-color: #ffffff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 15px;
  height: 500px;
  width: 300px;
  padding: 0;
  overflow-y: auto;
  z-index: 100000000;
}

.closeIconContainer {
  display: flex;
  justify-content: space-between;
  color: white !important;
  background-color: #69c;
}

.closeIconContainer {
  padding-right: 0.3rem;
}

.messageContainerTitle {
  padding: 0.3rem 0.5rem;
}

.groupButton,
.closeButton {
  color: white !important;
  font-size: 1.4rem !important;
  margin-left: 5px;
  cursor: pointer;
  padding: 0.5rem 0.2rem;
}

.searchBar {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #ccc;
  padding: 10px;
}

.searchBar input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 5px;
}

.searchIcon {
  cursor: pointer;
  margin-left: 5px;
}

.chatUsersList {
  overflow-y: auto;
  max-height: calc(100% - 110px);
}

.userListContainer {
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  cursor: pointer;
  text-transform: lowercase;
}

.userListContainer:hover {
  background-color: #f0f0f0;
}

.chatUsersList::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.chatUsersList::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.chatUsersList::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.user.unread {
  background-color: #f8d7da;
  border-left: 3px solid #dc3545;
}

.userListContainer.unread:hover {
  background-color: #f8d7da;
}

.userListContainer.unread .message-icon {
  color: #dc3545;
  font-weight: bold;
}

.userListContainer.unread:hover .message-icon {
  color: #dc3545;
}

.userListContainer.unread div {
  font-weight: bold;
}

@media (max-width: 600px) {
  .messageContainer {
    bottom: 0;
    right: 10px;
  }
}
