.wrapper {
  margin: 1rem 1rem 1.5rem 4.5rem;
  padding: 0.5rem 0;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 94vw;
}

.title {
  text-align: center;
  color: #333;
}

.viewBatchesMainContainer {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.buttonContainer {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  width: 90vw;
}

.buttonContainer Button {
  width: 300px;
}

.horizontalRow {
  margin: 1.5rem auto 0.5rem auto !important;
  width: 50% !important;
}

.batchTitle {
  text-align: center;
  color: #333;
  margin-top: 1rem;
}

.batchCardContainer {
  margin: 1rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  /* padding: 1rem; */
  width: auto;
  /* width: 80%; */
}

.cardContainer {
  background-color: #69c;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  width: 250px;
  height: 100px;
  border-radius: 10px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s;
}

.cardContainer:hover {
  scale: 1.05;
}

.modalBackground {
  display: table-cell;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.modal {
  background-color: white;
  padding: 20px;
  position: relative;
  top: 100px;
  opacity: 0;
  animation: modalFadeIn 0.5s forwards;
  animation-delay: 0.4s;
  text-align: center;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  border-radius: 5px;
}

.closeIconContainer {
  display: flex;
  justify-content: end;
  margin-top: -15px;
  margin-right: -15px;
  margin-bottom: 10px;
}

@keyframes modalFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.batchName {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-top: -30px;
  margin-bottom: 15px;
}

.modalPara {
  text-align: center;
  line-height: 1.7rem;
}

.batchButtons {
  height: 30px !important;
  margin-right: 0.2rem !important;
  margin-top: 5px !important;
  color: black !important;
  border: 1px solid black !important;
}

.noData {
  margin-top: -1rem !important;
  text-align: center !important;
  color: red;
  font-size: 1.1rem;
}

.dropdownContainer {
  width: 20%;
  margin: 2rem auto !important;
  text-align: center !important;
}

.dropdownContainer label {
  font-weight: bold;
  font-size: 1rem;
  color: #494949;
}

.deleteBatchContainer {
  margin-top: 0.5rem !important;
}

.deleteBatchButton {
  /* color: red !important; */
  color: black !important;
  font-size: 1.7rem !important;
}

.editBatchButton {
  color: black !important;
  font-size: 1.7rem !important;
}

@media screen and (max-width: 600px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    margin: 2rem auto;
    width: 90vw !important;
  }

  .buttonContainer Button {
    width: auto;
  }

  .dropdownContainer {
    width: 70%;
  }

  .batchCardContainer h4 {
    font-size: 1.3rem !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }

  .buttonContainer {
    display: flex;
    /* flex-direction: column; */
    margin: 2rem auto;
    width: 90vw !important;
  }

  .buttonContainer Button {
    width: auto;
  }

  .dropdownContainer {
    width: 40%;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .wrapper {
    width: 90vw;
    display: flex;
    flex-direction: column;
  }

  .buttonContainer {
    display: flex;
    width: 90vw !important;
  }

  .buttonContainer Button {
    width: auto;
  }

  .dropdownContainer {
    width: 40%;
  }
}
