.wrapper {
  margin: 1rem 1rem 1.5rem 4.5rem;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 94vw;
}

.attendanceHeader {
  text-align: center;
  color: #333;
}

.dropDownParentContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  gap: 1rem;
}

.dropDownContainer {
  flex: 1;
  min-width: 150px;
  margin: 0.5rem;
}

.dropDownLabel {
  font-weight: bold;
  font-size: 1rem;
  color: #494949;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 2rem !important;
  margin-top: 1rem !important;
}

.buttonsContainer Button {
  width: 190px;
}

.tableContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 90vw;
  margin: 2px auto;
}

.noData {
  text-align: center !important;
  color: red;
  font-size: 1.1rem;
}

@media screen and (max-width: 600px) {
  .wrapper {
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
  }

  .dropDownParentContainer {
    flex-direction: column;
    gap: 0.5rem;
  }

  .attendanceHeader {
    font-size: 1.6rem;
  }

  .tableContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 2px;
    margin-top: -10px;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .wrapper {
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 855px) and (max-width: 1160px) {
  .wrapper {
    margin: 1rem 0rem 1.5rem 5rem;
    width: 90vw;
    display: flex;
    flex-direction: column;
  }

  .dropDownParentContainer {
    flex-direction: column;
    gap: 0.5rem;
  }
}
