.wrapper {
  margin: 1rem 1rem 1.5rem 4.5rem;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 94vw;
}

.formContainer {
  border-radius: 10px;
  box-sizing: border-box;
  border-radius: 10px;
}

.practicalBatchContainer {
  width: 90vw;
  margin: 0.5rem auto;
  border-radius: 10px;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
}

.dropDownParentContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 1rem;
}

.formContainerTitle {
  color: #333;
  text-align: center;
  margin-top: -1.5rem;
}

.practicalBatchTitle {
  color: #333;
  text-align: center;
  margin-bottom: 1rem;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 2rem !important;
  margin-top: 1rem !important;
}

.buttonGroup Button {
  width: 250px;
}

.previousButton {
  cursor: pointer;
  height: 20px !important;
  width: 20px !important;
  color: black;
}

.inputGroup {
  flex: 1;
  min-width: 150px;
  margin: 0.5rem;
}

.inputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.inputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  width: 96%;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.practicalInputContainer {
  display: flex;
  justify-content: space-evenly;
  /* gap: 3rem; */
}

.practicalnputGroup {
  flex: 1;
  min-width: 150px;
  margin: 1rem auto !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.practicalnputGroup label {
  font-weight: bold;
  font-size: 20px;
  color: #494949;
  display: flex;
  justify-content: start;
}

.practicalnputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  width: 15%;
}

.accordionEdit {
  margin-left: 5px;
  height: 20px;
}

.tableContainerTitle {
  color: #333;
  text-align: center;
  margin: 0.5rem;
}

.tableHead {
  background-color: rgb(88, 147, 206) !important;
  text-align: center !important;
  color: white !important;
  font-size: 1rem !important;
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
  border-bottom: 1px solid black !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1000 !important;
  text-transform: uppercase;
}

.tableData {
  text-align: center !important;
  border: 1px solid black !important;
}

.tableContainer {
  max-height: 600px !important;
  overflow-y: auto;
  display: block;
  border: 1px solid rgb(177, 177, 177) !important;
}

.tableContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.tableContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.buttonContainer {
  display: flex !important;
  justify-content: center !important;
  margin: 1rem auto !important;
}

.paginationContainer {
  display: flex !important;
  justify-content: center !important;
}

.createdBatchTitle {
  text-align: center;
  margin-bottom: 0.5rem;
  color: #333;
}

.createdBatchContainer {
  padding: 0.5rem;
  width: 80%;
  margin: 0.5rem auto;
}

.theAccordion {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.8) !important;
  padding: 0.1rem;
}

.accordionContent {
  height: 10px !important;
  margin: 10px 0 !important;
  gap: 10px !important;
}

.batchButtonContainer {
  display: flex !important;
  justify-content: center !important;
  margin: 1.3rem auto !important;
}

.noData {
  text-align: center !important;
  color: red;
  font-size: 1.1rem;
}

@media screen and (max-width: 600px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }

  .formContainer,
  .practicalBatchContainer {
    width: 90vw;
  }

  .formContainerTitle {
    margin-top: -1rem;
  }

  .buttonGroup {
    flex-direction: column;
  }

  .buttonGroup Button {
    width: 100% !important;
  }

  .dropDownParentContainer {
    flex-direction: column;
    gap: 0.5rem;
  }

  .practicalInputContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center !important;
    align-items: center !important;
  }

  .practicalnputGroup input {
    width: 70%;
  }

  .accordionTableWrapper {
    overflow-x: auto;
    width: 100%;
  }

  .accordionTable {
    max-width: 500px;
    table-layout: fixed;
  }

  .accordionTable td,
  .accordionTable th {
    padding: 8px 12px;
    text-align: left;
  }

  .accordionTable tbody {
    display: block;
    max-height: 400px;
    overflow-y: auto;
  }

  .accordionTableContainer {
    overflow-x: auto;
    width: 100%;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .wrapper {
    margin: 1rem auto;
    width: 95vw;
    display: flex;
    flex-direction: column;
  }

  .formContainer,
  .practicalBatchContainer {
    width: 90vw;
  }

  .practicalnputGroup input {
    width: 50%;
  }

  .dropDownParentContainer {
    flex-direction: column;
  }
}

@media screen and (min-width: 855px) and (max-width: 1160px) {
  .wrapper {
    width: 90vw;
    display: flex;
    flex-direction: column;
  }

  .practicalBatchContainer {
    width: 85vw;
  }
}
