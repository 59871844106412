.groupChatContainer {
  position: fixed;
  bottom: 10px;
  right: 20px;
  background-color: #ffffff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 15px;
  height: 500px;
  width: 300px;
  padding: 0;
  overflow-y: auto;
  z-index: 100000000;
}

.closeIconContainer {
  display: flex;
  justify-content: space-between;
  color: white !important;
  background-color: #69c;
}

.closeIconContainer {
  padding-right: 0.3rem;
}

.deleteGroupButton,
.closeButton,
.infoButton {
  color: white !important;
  font-size: 1.4rem !important;
  cursor: pointer;
  padding: 0.5rem 0.2rem;
}

.infoButton {
  margin-right: 0.3rem;
  margin-top: 0.1rem;
}

.groupChatContainerTitle {
  padding: 0.3rem 0.5rem;
}

.buttonContainer {
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-around;
}

.membersButton,
.leaveButton {
  font-size: 0.9rem !important;
  height: 30px !important;
}

.groupChatMessages {
  margin-top: 0.2rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 15px 15px;
  height: 77%;
}

.groupMessage {
  border-radius: 5px !important;
  margin: 3px 5px !important;
  max-width: 70% !important;
  padding: 10px !important;
  word-wrap: break-word !important;
  word-break: break-all !important;
  opacity: 1 !important;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.sent {
  background-color: #69c;
  text-align: end;
  align-self: flex-end;
}

.received {
  background-color: #285e7b;
  text-align: start;
  align-self: flex-start;
}

.messageUsername,
.messageText,
.messageTime {
  font-size: 0.9rem;
  margin-left: 5px;
  color: white;
}

.groupMessageInput {
  display: flex;
  align-items: center;
  padding: 0.2rem;
  border-top: 1px solid #ccc;
  border-radius: 0 0 15px 15px;
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
  width: 97.5%;
  z-index: 1000;
}

.groupMessageInput input {
  flex: 1;
  padding: 0.4rem;
  border: 0;
  outline: none;
  border-radius: 5px;
  margin-right: 0.5rem;
  overflow-wrap: break-word;
}

.sendButton {
  background-color: #69c;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
}

.sendButton:hover {
  background-color: #02366e;
}

.groupChatMessages::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.groupChatMessages::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.groupChatMessages::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

@media (max-width: 600px) {
  .groupChatContainer {
    bottom: 5px;
    right: 40px;
  }
}
