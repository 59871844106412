.educationDetails {
  text-align: start;
}

.educationContainer {
  margin-bottom: 3rem;
}

.headingCurrent {
  line-height: 1.9rem;
  color: rgb(40, 44, 63);
}

.headingPrevious {
  color: rgb(40, 44, 63);
  margin-top: -10px;
}

.horizontalRow {
  width: 100%;
  margin-top: 10px;
}

.educationContent {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  padding: 2rem;
  gap: 2rem;
  margin: 25px 0;
  width: 100%;
  max-width: 1520px;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 2rem;
}

.closeIconContainer {
  display: flex !important;
  justify-content: end !important;
  margin-top: -60px;
  margin-right: -30px;
  margin-bottom: 10px;
}

.detailsContainer {
  flex-grow: 1;
}

.detailsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.courseInfo {
  margin-top: -1.5rem;
  text-transform: uppercase;
}

.courseInfo span {
  font-weight: bold;
}

.courseStats {
  display: flex;
  align-items: center;
}

.educationEditButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Lato, Roboto, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #69c;
  border: 2px solid #69c;
  border-radius: 10px;
  background: none;
  padding: 0.2rem;
  cursor: pointer;
  width: 105px;
  flex-shrink: 0;
  margin-top: -15px;
  margin-right: 15px;
}

.educationEditButton:hover {
  background-color: #69c;
  color: #ffff;
}

.additionalInfo p {
  font-family: Lato, Roboto, sans-serif;
  font-size: 1.1rem;
}

.additionalInfo span {
  font-weight: bold;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
}

.downloadMarksheetButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Lato, Roboto, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #69c;
  border: 2px solid #69c;
  border-radius: 10px;
  background: none;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  width: 140px;
  flex-shrink: 0;
  margin-top: 10px;
}

.downloadMarksheetButton:hover {
  background-color: #69c;
  color: #ffff;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.noData {
  color: rgb(228, 55, 55);
  font-size: 1rem;
  margin-top: 5px;
}

.addButton {
  margin-top: 1rem !important;
  background-color: green !important;
}

.addButton:hover {
  background-color: rgb(0, 65, 0) !important;
}

.icon {
  height: 35px;
  margin-top: -10px;
  margin-left: 5px;
  vertical-align: middle;
}

.aboutIcon {
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
  margin-top: -5px;
}

@media screen and (max-width: 600px) {
  .educationContent {
    width: 92vw;
  }

  .educationEditButton {
    margin-right: -10px;
  }

  .closeIconContainer {
    display: flex !important;
    justify-content: end !important;
    margin-top: -100px;
    margin-right: -30px;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .educationContent {
    width: 92vw;
  }

  .educationEditButton {
    margin-left: 10px;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .educationContent {
    width: 90vw;
  }

  .educationEditButton {
    margin-left: 10px;
  }
}
