.formContainer {
  margin: 2rem auto;
  width: 33%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  padding: 20px 5px 20px 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  font-family: "Arial", sans-serif;
}

.closeIconContainer {
  display: flex !important;
  justify-content: end !important;
  margin-top: -20px;
  margin-right: -5px;
  margin-bottom: 10px;
}

.addCourseScrollable {
  max-height: 70vh;
  overflow: auto;
  padding-right: 16px;
}

.addCourseScrollable::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.addCourseScrollable::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.addCourseScrollable::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.formContainerTitle {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: -30px;
}

.courseInputGroup {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  text-align: start;
}

.courseInputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.courseInputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.saveButtonContainer {
  display: flex;
  justify-content: center;
}

.span {
  font-size: 14px;
  color: rgb(228, 55, 55);
}

.error {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .formContainer {
    width: 95vw;
  }

  /* .courseInputGroup label {
    font-size: 16px;
  } */
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .formContainer {
    width: 75vw;
    margin-top: 3rem !important;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .formContainer {
    width: 60vw;
    margin-top: 3rem !important;
  }
}
