.headingCertifications {
  color: rgb(40, 44, 63);
}

.headingAchievements {
  color: rgb(40, 44, 63);
}

.addButton {
  margin-top: 1rem !important;
  background-color: green !important;
}

.icon {
  height: 35px;
  margin-top: -5px;
  margin-left: 5px;
  vertical-align: middle;
}

.noData {
  color: rgb(228, 55, 55);
  font-size: 1rem;
  margin-top: 5px;
}

.certificationContent span {
  font-weight: bold;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
}

.certificationContent p {
  font-family: Lato, Roboto, sans-serif;
}

.certificationsList {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.horizontalRow {
  margin-top: 10px;
  width: 100%;
}

.yourCertification {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  padding: 15px;
  border: 1px solid #ccc;
  width: 310px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  overflow: hidden;
  line-height: 2rem;
}

.yourCertification h3 {
  text-transform: uppercase;
  word-wrap: break-word;
  width: 93%;
}

.yourAchievement h3 {
  text-transform: uppercase;
  word-wrap: break-word;
  width: 93%;
}

.certificationContent {
  max-height: calc(45vh - 30px);
  overflow: auto;
  padding-right: 10px;
  width: 100%;
  word-wrap: break-word;
}

.certificationContent::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.certificationContent::-webkit-scrollbar-thumb {
  background-color: rgba(191, 193, 217, 0.7);
  border-radius: 10px;
}

.certificationContent::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.closeIconContainer {
  position: absolute;
  top: 1px;
  right: 2px;
  z-index: 1;
}

.aboutIcon {
  height: 22px;
  margin-right: 5px;
  vertical-align: middle;
  margin-top: -5px;
}

.achievementsSection {
  margin-top: 2rem;
}

.achievementsList {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.yourAchievement {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  padding: 15px;
  border: 1px solid #ccc;
  width: 310px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  overflow: hidden;
  line-height: 2rem;
}

.achievementContent {
  max-height: calc(45vh - 30px);
  overflow: auto;
  padding-right: 10px;
  width: 100%;
  word-wrap: break-word;
}

.achievementContent::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.achievementContent::-webkit-scrollbar-thumb {
  background-color: rgba(191, 193, 217, 0.7);
  border-radius: 10px;
}

.achievementContent::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.achievementContent span {
  font-weight: bold;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
}

.achievementContent p {
  font-family: Lato, Roboto, sans-serif;
}

@media screen and (max-width: 600px) {
  .yourCertification,
  .yourAchievement {
    width: 86.5vw;
    padding: 10px;
  }

  .certificationsList,
  .achievementsList {
    gap: 10px;
  }

  .addButton {
    width: 90%;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .addButton {
    width: 40%;
  }

  .certificationsList,
  .achievementsList {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 95vw;
    gap: 20px;
  }

  .yourCertification,
  .yourAchievement {
    width: 40vw;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .addButton {
    width: 30%;
  }
}
