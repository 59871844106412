.editEducationContainer {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  padding: 20px 5px 20px 20px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 30%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Arial", sans-serif;
  text-align: center;
  overflow: hidden;
}

.editEducationScrollable {
  max-height: calc(65vh - 25px);
  overflow: auto;
  padding-right: 16px;
}

.editEducationScrollable::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.editEducationScrollable::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.editEducationScrollable::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.closeIconContainer {
  display: flex !important;
  justify-content: end !important;
  margin-top: -20px;
  margin-right: -5px;
  margin-bottom: 10px;
}

.error {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

.editEducationHeader {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-top: -30px;
  margin-bottom: 15px;
}

.editInputs {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  text-align: start;
}

.editInputs label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.editInputs input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.span {
  font-size: 14px;
  color: rgb(228, 55, 55);
}

@media (max-width: 600px) {
  .editEducationContainer {
    width: 95vw;
  }

  /* .editInputs label {
    font-size: 16px;
  }

  .editInputs input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  } */
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .editEducationContainer {
    width: 75vw;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .editEducationContainer {
    width: 60vw;
  }
}
