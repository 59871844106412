.wrapper {
  margin: 1rem 1rem 1.5rem 4.5rem;
  padding: 0.5rem 0;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 94vw;
  height: auto;
}

.title {
  text-align: center;
  color: #333333;
}

@media screen and (max-width: 600px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }

  /* .title {
    font-size: 1.6rem;
  } */
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }
}

@media screen and (min-width: 855px) and (max-width: 1160px) {
  .wrapper {
    margin: 1rem 0rem 1.5rem 5rem;
    width: 90vw;
    display: flex;
    flex-direction: column;
  }
}
