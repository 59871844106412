.main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10rem;
  height: 87vh;
  box-sizing: border-box;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.theNavbar {
  background-color: #69c !important;
}

.navbarLogo {
  width: 13rem;
}

#imageLogin {
  width: 20rem;
  height: auto;
}

#logoLogin {
  width: 23rem;
  padding: 0.5rem;
}

.rightContainer {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  max-width: 310px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 2rem;
}

.rightContainer h2 {
  text-align: start;
  color: #333;
}

.footer {
  text-align: center;
  padding: 0.5rem;
}

.footer p {
  font-size: 13px;
}

.loginGroup {
  margin: 15px 0;
}

.loginGroup label {
  font-weight: bold;
  font-size: 16px;
  color: #494949;
  margin-bottom: 5px;
}

.loginGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.btnUpdate {
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  height: 37px;
}

/* .btnUpdate:hover {
  background-color: #0056b3;
} */

.error {
  color: red;
  font-size: 0.8rem;
}

/* Media Queries */

@media screen and (max-width: 600px) {
  .main {
    flex-direction: column;
    justify-content: center;
    /* margin-top: -1rem !important; */
    gap: 0;
  }

  #imageLogin {
    width: 16rem;
  }

  #logoLogin {
    width: 18rem;
  }

  .rightContainer {
    max-width: 75%;
    margin: 10px auto;
  }

  .loginGroup input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }

  .footer {
    margin-top: 2rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 850px) {
  .main {
    flex-direction: column;
    justify-content: center;
    margin: 1px auto;
    gap: 0;
  }

  #imageLogin {
    width: 20rem;
  }

  #logoLogin {
    width: 23rem;
  }

  .rightContainer {
    max-width: 45%;
    height: auto;
    margin: 10px auto;
  }

  .loginGroup input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
}

@media screen and (min-width: 851px) and (max-width: 1160px) {
  .main {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10rem;
    height: 73vh;
    box-sizing: border-box;
  }
}
