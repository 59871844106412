.recruiterRegContainer {
  height: 70vh;
  overflow: auto;
  padding-right: 16px;
  width: 100%;
}

.recruiterRegContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.recruiterRegContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.recruiterRegContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.recruiterRegContainerHeader {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  text-align: start;
}

.inputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.inputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.inputbox {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(145, 145, 145);
}

.submitButtonContainer {
  margin: 1rem auto !important;
  display: flex !important;
  justify-content: center !important;
}

.error {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

.departmentNamesContainer {
  padding: 0 0 0.5rem 0;
  display: flex !important;
  gap: 1rem;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
}

.departmentNamesContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.departmentNamesContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.departmentNamesContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.departmentNameButton {
  display: inline-flex;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 150px;
  height: 35px;
  border-radius: 10px !important;
  font-size: 0.7rem !important;
  background-color: #69c !important;
  color: white !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 8px;
  box-sizing: border-box;
}

.selectOption {
  height: 40px;
  /* padding: 8px; */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.noDepartmentText {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin: 1px auto !important;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .recruiterRegContainer {
    height: 65vh;
  }

  .recruiterRegContainerHeader {
    font-size: 1.3rem;
  }

  .inputGroup {
    width: 100% !important;
  }

  .submitButtonContainer {
    margin: 5px auto !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 1160px) {
  .recruiterRegContainer {
    overflow: auto;
    padding-right: 16px;
    width: 100%;
    height: 67vh;
  }
}
