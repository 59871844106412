.wrapper {
  margin: 1rem 1rem 1.5rem 4.5rem;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 94vw;
}

.instituteHeader {
  text-align: center;
  color: #333;
}

.addInstituteButtonContainer {
  margin: 1.5rem auto !important;
  display: flex;
  justify-content: center;
}

.searchLabel {
  font-weight: bold;
  font-size: 1rem;
  color: #494949;
  display: block;
  text-align: center;
}

.searchBar {
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  display: flex;
  width: 25%;
  margin: 0.1px auto !important;
}

.dropdownParentContainer {
  width: 100%;
  margin: 1px auto !important;
}

.dropDownContainer {
  min-width: 150px;
}

.containerTable {
  margin: 1rem auto !important;
  width: 90vw !important;
  max-height: 600px !important;
}

.tableHead {
  background-color: rgb(88, 147, 206) !important;
  text-align: center !important;
  color: white !important;
  font-size: 1rem !important;
  border: 1px solid black !important;
  position: sticky !important;
  top: 0 !important;
  text-transform: uppercase;
  z-index: 1 !important;
}

.tableData {
  text-align: center !important;
  border: 1px solid black !important;
}

.containerTable::-webkit-scrollbar {
  height: 4px !important;
  width: 4px !important;
}

.containerTable::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7) !important;
  border-radius: 10px !important;
}

.containerTable::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255) !important;
}

.buttonContainer {
  display: flex;
  z-index: -100000000000 !important;
}

.modal {
  background: rgba(0, 0, 0, 0.5) !important;
}

.editInstituteContainer {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  padding: 20px 5px 20px 20px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 30%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Arial", sans-serif;
  text-align: center;
  overflow: hidden;
}

.closeIconContainer {
  display: flex !important;
  justify-content: end !important;
  margin-top: -20px;
  margin-right: -5px;
}

.editInstituteHeader {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: -20px;
}

.editInstituteScrollable {
  max-height: calc(65vh - 25px);
  overflow: auto;
  padding-right: 16px;
}

.editInstituteScrollable::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.editInstituteScrollable::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.editInstituteScrollable::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  text-align: start;
}

.inputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.inputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.disabled {
  background-color: #f6f6f6 !important;
  color: #a0a0a0 !important;
  /* cursor: not-allowed !important; */
}

.noData {
  margin-top: 2rem !important;
  margin-bottom: 0.2rem !important;
  text-align: center !important;
  color: red;
  font-size: 1.1rem;
}

.error {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

.span {
  font-size: 14px;
  color: rgb(228, 55, 55);
}

@media screen and (max-width: 600px) {
  .wrapper {
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
  }

  .editInstituteContainer {
    width: 95vw;
  }

  .searchBar {
    width: 70%;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .wrapper {
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
  }

  .editInstituteContainer {
    width: 75vw;
  }

  .searchBar {
    width: 40%;
  }
}

@media screen and (min-width: 855px) and (max-width: 1160px) {
  .wrapper {
    margin: 1rem 0rem 1.5rem 5rem;
    width: 90vw;
    display: flex;
    flex-direction: column;
  }

  .editInstituteContainer {
    width: 60vw;
  }

  .containerTable {
    width: 88vw !important;
  }

  .searchBar {
    width: 40%;
  }
}
