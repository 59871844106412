.scheduleContainer {
  box-sizing: border-box;
}

.scheduleTitle {
  text-align: center;
  color: #333;
}

.timetable {
  margin: 0 0.5rem;
}

.timetableHeader {
  text-align: center;
  color: #333;
}

.lectureContainer {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  margin: 0.5rem auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4) !important;
  background-color: #fcfcfc;
  border-radius: 15px;
  height: 200px;
  padding: 0 0.5rem;
}

.dayTitleContainer {
  height: 11rem;
  width: 19%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid rgb(103, 103, 103);
  margin: 0 0.5rem;
}

.dayTitle {
  text-align: center;
}

.timetableCard {
  position: relative;
  width: 100%;
  max-width: 350px;
  margin: 10px;
  border-radius: 15px !important;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.9) !important;
  background: linear-gradient(to right, #614385, #516395);
  overflow: hidden;
  transition: transform 0.4s cubic-bezier(0.17, 0.67, 0.5, 1.03);
  cursor: pointer !important;
  flex-shrink: 0;
  height: 170px;
}

.cardContent {
  padding: 14px;
  background: linear-gradient(to right, #614385, #516395);
  color: #ffffff !important;
  transition: opacity 0.4s ease-in-out;
  cursor: pointer !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 15px !important;
}

.subjectName {
  font-weight: bold;
  /* color: #c32361; */
  color: #ffffff !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cardDetails {
  opacity: 0;
  transition: opacity 0.4s ease-in-out 0.15s;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #d1cece !important;
}

.timetableCard:hover .cardDetails {
  opacity: 1;
  color: #d1cece !important;
}

.time,
.actionButtons {
  color: #d1cece !important;
}

.noLectures {
  text-align: center;
  margin-top: 5.6rem;
  margin-left: -2rem;
}

.noData {
  text-align: center;
  color: red;
  font-size: 1.1rem;
}

.cardContainer {
  display: flex !important;
  flex-direction: row !important;
  gap: 20px !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;
  height: 200px !important;
  width: 100% !important;
}

.cardContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.cardContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.cardContainer::-webkit-scrollbar-track {
  background-color: transparent;
}

.addLectureButton {
  font-size: 30px !important;
  margin: 5px 20px 0 20px !important;
  cursor: pointer;
  color: black !important;
}

.modalBackground {
  background-color: rgba(0, 0, 0, 0.5);
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* z-index: 1; */
}

.modal {
  background-color: white;
  padding: 20px;
  position: relative;
  top: 100px;
  opacity: 0;
  animation: modalFadeIn 0.5s forwards;
  animation-delay: 0.4s;
  text-align: center;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  border-radius: 5px;
}

.closeIconContainer {
  display: flex;
  justify-content: end;
  margin-top: -15px;
  margin-right: -15px;
  margin-bottom: 10px;
}

.modalSubjectHeader {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-top: -30px;
  margin-bottom: 15px;
}

.editInputs {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  text-align: start;
}

.editInputs label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.editInputs input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

@keyframes modalFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.error {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .scheduleContainer {
    margin: 0.5rem auto;
    flex-direction: column;
  }

  .scheduleTitle {
    font-size: 1.6rem;
  }

  .lectureContainer {
    flex-direction: column;
    border-radius: 10px;
    height: auto;
    width: 87vw;
    margin: 0.5rem auto;
  }

  .cardContainer {
    flex-direction: column !important;
  }

  .cardDetails {
    opacity: 1;
    color: #d1cece !important;
    display: block;
  }

  .noLectures {
    margin-top: 2.5rem;
    margin-left: 0;
  }

  .addLectureButton {
    margin-top: 5px !important;
  }

  .modal {
    width: 80vw;
  }

  .dayTitleContainer {
    height: 2rem;
    border: none;
    width: 100%;
    display: block;
    margin: 0.5rem;
  }

  /* .timetableCard:hover .cardDetails {
    opacity: 1;
    color: #d1cece !important;
  } */
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .scheduleContainer {
    margin: 0.5rem auto;
    flex-direction: column;
  }

  .scheduleTitle {
    font-size: 1.6rem;
  }

  .lectureContainer {
    flex-direction: column;
    border-radius: 10px;
    height: auto;
    width: 87vw;
    margin: 0.5rem auto;
  }

  .cardContainer {
    flex-direction: column !important;
  }

  .cardDetails {
    opacity: 1;
    color: #d1cece !important;
    display: block;
  }

  .noLectures {
    margin-top: 2.5rem;
    margin-left: 0;
  }

  .addLectureButton {
    margin-top: 5px !important;
  }

  .modal {
    width: 80vw;
  }

  .dayTitleContainer {
    height: 2rem;
    border: none;
    width: 100%;
    display: block;
    margin: 0.5rem;
  }

  .timetableCard {
    max-width: 200px;
  }

  /* .timetableCard:hover .cardDetails {
    opacity: 1;
    color: #d1cece !important;
  } */
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .scheduleContainer {
    margin: 0.5rem auto;
    flex-direction: column;
  }

  .cardContainer {
    flex-direction: column !important;
  }

  .dayTitle {
    font-size: 1.2rem;
  }

  .timetableCard {
    max-width: 200px;
  }

  .cardDetails {
    opacity: 1;
    color: #d1cece !important;
    display: block;
  }

  .noLectures {
    margin-left: 0;
  }
}
