.singleRegisterationContainer {
  height: 73vh;
  overflow: auto;
  padding-right: 16px;
  width: 100%;
}

.singleRegisterationContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.singleRegisterationContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.singleRegisterationContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.singleTitle {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  /* margin-bottom: 15px; */
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  text-align: start;
}

.inputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.inputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.datePicker {
  width: 100% !important;
}

.inputbox {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(145, 145, 145);
}

.saveButton {
  margin: 1rem auto !important;
  display: flex !important;
  justify-content: center !important;
}

.error {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .singleRegisterationContainer {
    height: 65vh;
  }

  .inputGroup {
    width: 100% !important;
  }

  .singleTitle {
    font-size: 1.3rem;
  }

  .inputGroup input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 1160px) {
  .singleRegisterationContainer {
    height: 70vh;
    overflow: auto;
    padding-right: 16px;
    width: 100%;
  }

  .inputGroup {
    width: 100% !important;
    margin: 0.1rem auto !important;
  }

  .inputGroup input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
}
