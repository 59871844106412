.editAttendanceContainer {
  box-sizing: border-box;
}

.containerTitle {
  text-align: center;
  color: #333;
}

.arrowContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 5px;
}

.previousButton,
.nextButton {
  cursor: pointer;
  height: 20px !important;
  width: 20px !important;
  color: black;
}

.tableContainer {
  max-height: 600px;
  overflow-y: auto;
  display: block;
  border: 1px solid black !important;
}

.tableHead {
  background-color: rgb(88, 147, 206) !important;
  text-align: center !important;
  color: white !important;
  font-size: 1rem !important;
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
  border-bottom: 1px solid black !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1000 !important;
  text-transform: uppercase;
}

.tableData {
  text-align: center !important;
  border: 1px solid black !important;
}

.tableContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.tableContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.modalBackground {
  display: table-cell;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.modal {
  padding: 2rem;
  position: relative;
  top: 100px;
  opacity: 0;
  animation: modalFadeIn 0.5s forwards;
  animation-delay: 0.4s;
  background-color: white;
  text-align: center;
  width: 280px;
  margin: 0 auto;
  border-radius: 5px;
}

.closeIconContainer {
  display: flex;
  justify-content: end;
  margin-top: -30px;
  margin-right: -30px;
}

.modalTitle {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-top: -15px;
  margin-bottom: 15px;
}

.modalPara {
  text-align: center;
  line-height: 1.5rem;
}

.inputGroup {
  flex: 1;
  margin: 1rem auto;
  margin-bottom: 0;
  width: 80% !important;
}

.inputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
  text-align: start !important;
}

@keyframes modalFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 600px) {
  .editAttendanceContainer {
    padding: 1rem 0rem;
    box-sizing: border-box;
    width: 90vw;
  }

  .modal {
    width: 75vw;
    top: 20%;
  }

  .containerTitle {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .editAttendanceContainer {
    width: 90vw;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .editAttendanceContainer {
    width: 85vw;
  }
}
