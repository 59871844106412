.Icon {
  color: white !important;
}

.classroom {
  height: 23px;
}

.course,
.subject {
  height: 23px;
  margin-left: 2px;
}

.messageIcon {
  height: 27px;
}

.drawerStyled {
  transition: transform 0.9s ease-in-out;
}

.hideDrawer {
  transform: translateX(-100%);
  transition: transform 0.9s ease-in-out;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  background-color: rgba(211, 211, 211, 0.8);
  color: #333;
  text-align: center;
  border-radius: 5px;
  position: absolute;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 9999;
  position: fixed;
  left: 2.2rem !important;
  /* max-width: 150px; */
  width: auto;
  padding: 10px;
  font-size: 0.7rem !important;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-profileText {
  visibility: hidden;
  /* background-color: rgba(134, 134, 134, 0.8);
  color: #ffffff; */
  background-color: rgba(211, 211, 211, 0.8);
  color: #333;
  text-align: center;
  border-radius: 5px;
  position: absolute;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 9999;
  top: 3.5rem !important;
  right: 5rem !important;
  position: fixed;
  /* max-width: 100px; */
  width: auto;
  padding: 10px;
  font-size: 0.7rem !important;
}

.tooltip-container:hover .tooltip-profileText {
  visibility: visible;
  opacity: 1;
}
