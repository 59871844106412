.percentWiseAttendanceContainer {
  /* padding: 0.5rem; */
  box-sizing: border-box;
  width: 90% !important;
  margin: 1px auto !important;
}

.containerTitle {
  text-align: center;
  color: #333;
  margin-top: 10px;
}

.downloadButtonContainer {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.tableContainer {
  max-height: 600px;
  overflow-y: auto;
  display: block;
  border: 1px solid black !important;
}

.arrowContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 5px;
}

.previousButton,
.nextButton {
  cursor: pointer;
  height: 20px !important;
  width: 20px !important;
  color: black;
}

.tableHead {
  background-color: rgb(88, 147, 206) !important;
  text-align: center !important;
  color: white !important;
  font-size: 1rem !important;
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
  border-bottom: 1px solid black !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1000 !important;
  text-transform: uppercase;
}

.tableData {
  text-align: center !important;
  border: 1px solid black !important;
}

.noDataTitle {
  text-align: center;
  color: red;
  margin: 1rem;
  font-size: 1.1rem;
}

.tableContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.tableContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

@media screen and (max-width: 600px) {
  .percentWiseAttendanceContainer {
    width: 90vw !important;
    flex-direction: column;
  }

  .downloadButtonContainer {
    display: flex !important;
    justify-content: center !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .percentWiseAttendanceContainer {
    width: 90vw !important;
    flex-direction: column;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .percentWiseAttendanceContainer {
    width: 85vw !important;
    flex-direction: column;
  }
}
