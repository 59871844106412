.wrapper {
  margin: 1rem 1rem 1.5rem 4.5rem;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 94vw;
  height: 300px;
}

.header {
  text-align: center;
  color: #69c;
}

.greetHeader {
  text-align: center;
  color: #69c;
  margin-top: 1rem;
}

@media screen and (max-width: 600px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .wrapper {
    margin: 1rem auto;
    width: 95vw;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 855px) and (max-width: 1160px) {
  .wrapper {
    width: 90vw;
    display: flex;
    flex-direction: column;
  }
}
