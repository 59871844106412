.formContainer {
  margin: 2rem auto;
  width: 33%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  padding: 20px 5px 20px 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  font-family: "Arial", sans-serif;
}

.closeIconContainer {
  display: flex !important;
  justify-content: end !important;
  margin-top: -20px;
  margin-right: -5px;
  margin-bottom: 10px;
}

.addJobtitle {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-top: -40px;
  margin-bottom: 15px;
}

.addJobContainer {
  max-height: 70vh;
  overflow: auto;
  padding-right: 16px;
}

.addJobContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.addJobContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.addJobContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  text-align: start;
}

.inputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.inputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.jobDescriptionInput {
  padding: 0.3rem;
  border: 1px solid #ccc;
}

.departmentListContainer {
  padding: 0.1rem 0 0.5rem 0;
  display: flex !important;
  gap: 1rem;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
  margin-bottom: 0.5rem !important;
}

.departmentListContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.departmentListContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.departmentListContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.skillsContainer {
  padding: 0.4rem 0 0.2rem 0;
  display: flex !important;
  gap: 1rem;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
  margin-bottom: 0.5rem !important;
}

.skillsContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.skillsContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.skillsContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.departmentNameButton {
  display: inline-flex;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100px;
  height: 35px;
  /* border-radius: 10px !important; */
  font-size: 0.7rem !important;
  background-color: #69c !important;
  color: white !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 8px;
  box-sizing: border-box;
}

.skillButton {
  display: inline-flex;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100px;
  height: 35px;
  /* border-radius: 10px !important; */
  font-size: 0.7rem !important;
  background-color: #69c !important;
  color: white !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 8px;
  box-sizing: border-box;
}

.selectOption {
  height: 40px;
  /* padding: 8px; */
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.noDepartmentText,
.noSkillsText {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin: 1px auto !important;
  text-align: center;
}

.submitButton {
  margin: 1px auto !important;
  display: flex !important;
  justify-content: center !important;
}

.error {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .formContainer {
    width: 95vw;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .formContainer {
    width: 75vw;
    margin-top: 3rem !important;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .formContainer {
    width: 60vw;
    margin-top: 3rem !important;
  }
}
