.wrapper {
  margin: 1rem 1rem 1.5rem 4.5rem;
  padding: 0.5rem 0;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 94vw;
}

.postJobButtonContainer {
  margin: 1.5rem auto !important;
  display: flex;
  justify-content: center;
  width: 100%;
}

.filterStudentsButton {
  margin-right: 10px !important;
}

.title {
  text-align: center;
  color: #333;
}

.prevIconContainer {
  margin-top: -45px;
}

.searchLabel {
  font-weight: bold;
  font-size: 1rem;
  color: #494949;
  display: block;
}

.searchBar {
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 92%;
}

.dropDownParentContainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 1rem auto;
  gap: 1rem;
}

.dropDownContainer {
  flex: 1;
  min-width: 150px;
  margin: 0 1rem;
}

.dropDownLabel {
  font-weight: bold;
  font-size: 1rem;
  color: #494949;
}

.datePicker {
  width: 100%;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.noJobTextContainer {
  display: block;
  width: 100%;
}

.noJobText {
  text-align: center;
  color: red;
  font-size: 1.1rem;
  margin-top: 2.5rem;
}

.jobCardContainer {
  background-color: rgb(255, 253, 253);
  margin: 2rem auto !important;
  width: 100% !important;
  max-width: 850px !important;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
}

.jobCardContainer:hover {
  transform: translateY(-5px);
}

.jobTitle {
  font-size: 1.8rem;
  padding: 0.2rem;
  font-weight: 600;
  color: white;
  background-color: #69c;
}

.jobInfo {
  display: flex;
  justify-content: space-between;
  background-color: #69c;
  padding: 0.2rem;
}

.salary {
  font-size: 1.3rem;
  font-weight: 600;
  color: white;
}

.additionalJobInfo {
  padding-bottom: 2rem;
  margin-top: 0.5rem;
  padding: 0.2rem 0.5rem !important;
  max-height: calc(40vh - 45px);
  overflow: auto;
  word-wrap: break-word;
}

.additionalJobInfo::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.additionalJobInfo::-webkit-scrollbar-thumb {
  /* background-color: rgba(121, 127, 205, 0.7); */
  background-color: #69c;
  border-radius: 10px;
}

.additionalJobInfo::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.additionalJobInfo h3 {
  font-size: 1.2rem !important;
  display: flex !important;
  flex-wrap: wrap;
}

.additionalJobInfo p {
  margin-left: 0.5rem !important;
  margin-top: 0.1rem !important;
  word-wrap: break-word;
}

.jobType {
  font-size: 1.4rem;
  color: white;
  padding: 0.3rem 0.3rem;
  background-color: #69c;
  margin-top: -0.7px;
}

.description {
  display: flex !important;
  flex-direction: column !important;
}

.skillButton {
  font-size: 0.8rem !important;
  text-transform: none;
  background-color: #3498db !important;
  color: white !important;
  margin: 0.3rem !important;
  margin-top: -0.3rem !important;
}

.buttonsContainer {
  width: 12%;
  margin: 0.5rem auto !important;
  margin-bottom: -1rem !important;
  display: flex;
  justify-content: space-between;
}

.editButton {
  font-size: 2rem !important;
  cursor: pointer;
}

.closeIconContainer {
  display: flex !important;
  justify-content: end !important;
  margin-top: -20px;
  margin-right: -5px;
  margin-bottom: 10px;
}

.editJobContainer {
  background-color: #ffffff;
  /* border: 1px solid #e0e0e0; */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  padding: 20px 5px 20px 20px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 30%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Arial", sans-serif;
  text-align: center;
  overflow: hidden;
}

.error {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

.editJobScrollable {
  max-height: calc(65vh - 25px);
  overflow: auto;
  padding-right: 16px;
}

.editJobHeader {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-top: -40px;
  margin-bottom: 15px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  text-align: start;
}

.inputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.inputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.textareaContainer {
  padding: 0.3rem;
  border: 1px solid #ccc;
}

.noData {
  text-align: center !important;
  color: red;
  font-size: 1.1rem;
  margin-bottom: 0.2rem !important;
}

.editJobScrollable::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.editJobScrollable::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.editJobScrollable::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.modal {
  background: rgba(0, 0, 0, 0.5);
}

.span {
  font-size: 14px;
  color: rgb(228, 55, 55);
}

.instituteNamesContainer {
  padding: 0.1rem 0 0.5rem 0;
  display: flex !important;
  gap: 1rem;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
  margin-bottom: 0.5rem !important;
}

.instituteNamesContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.instituteNamesContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.instituteNamesContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.skillsContainer {
  padding: 0.4rem 0 0.2rem 0;
  display: flex !important;
  gap: 0.1rem;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
  margin-bottom: 0.5rem !important;
}

.skillsContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.skillsContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.skillsContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.instituteNameButton {
  display: inline-flex;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100px;
  height: 35px;
  /* border-radius: 10px !important; */
  font-size: 0.7rem !important;
  background-color: #69c !important;
  color: white !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 8px;
  box-sizing: border-box;
}

.skillButton {
  display: inline-flex;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100px;
  height: 35px;
  /* border-radius: 10px !important; */
  font-size: 0.7rem !important;
  background-color: #69c !important;
  color: white !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 8px;
  box-sizing: border-box;
}

.selectOption {
  height: 40px;
  /* padding: 8px; */
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.noInstituteText,
.noSkillsText {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin: 1px auto !important;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .wrapper {
    margin: 1rem auto;
    flex-direction: column;
  }

  .buttonsContainer {
    width: 30%;
  }

  .editJobContainer {
    width: 95vw;
  }

  .dropDownParentContainer {
    flex-direction: column;
    padding: 0 0.5rem;
  }

  .dropDownContainer {
    max-width: 100%;
    margin: 0.5rem 0;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .wrapper {
    width: 96vw;
    margin: 1rem auto;
    flex-direction: column;
  }

  .buttonsContainer {
    width: 20%;
  }

  .editJobContainer {
    width: 75vw;
  }

  .dropDownParentContainer {
    flex-direction: column;
    padding: 0 0.5rem;
  }

  .dropDownContainer {
    max-width: 100%;
    margin: 0.5rem 0;
  }

  .searchBar {
    width: 89vw;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .wrapper {
    width: 91vw;
    flex-direction: column;
  }

  .buttonsContainer {
    width: 15%;
  }

  .editJobContainer {
    width: 60vw;
  }

  .dropDownParentContainer {
    flex-direction: column;
    padding: 0 0.5rem;
  }

  .dropDownContainer {
    max-width: 100%;
    margin: 0.5rem 0;
  }

  .searchBar {
    width: 87vw;
  }
}
