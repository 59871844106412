.leftSideContainer {
  width: 18%;
  padding: 2rem;
  border: 0.1rem solid rgb(229, 229, 229);
  position: fixed;
  z-index: 1;
  height: calc(100% - 6rem);
  min-height: calc(100% - 6.5rem);
  overflow: scroll;
}

.burgerIcon {
  display: none;
}

.photoContainer {
  width: 80%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2.5rem;
}

.nameCardHeader {
  text-align: center !important;
  font-weight: bold !important;
}

.leftSideContainerNavbar {
  list-style: none;
  margin-top: 5px;
  padding: 8px 0px;
  margin-left: 45px;
  width: 100% !important;
}

.optionContainer {
  padding: 3px;
  margin-left: 5px;
}

.optionContainer .link {
  text-decoration: none;
}

.optionContainer:hover {
  background-color: rgba(37, 29, 147, 0.1);
  border-radius: 0.2rem;
  padding: 8px;
}

.optionText {
  cursor: pointer;
  color: rgb(59, 50, 179);
  font-size: 1rem;
  margin-left: -5px;
}

.rightSideContainer {
  margin-left: 19rem;
  padding: 3rem;
  margin-top: -40px;
}

@media screen and (max-width: 600px) {
  .leftSideContainer {
    display: none;
  }

  .rightSideContainer {
    margin-top: -50px;
    margin-left: -2rem;
  }

  .burgerIcon {
    display: block;
    position: fixed;
    right: 20px;
    bottom: 10px;
    z-index: 9999;
    color: #fff !important;
    padding: 8px;
    border-radius: 50% !important;
    cursor: pointer;
    background-color: #2c2c56 !important;
  }

  .drawer {
    width: 150px;
  }

  .drawerList {
    background-color: transparent !important;
  }

  .drawerListItems {
    margin-bottom: 5px;
    color: rgb(71, 79, 124) !important;
    text-align: center;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .leftSideContainer {
    display: none;
  }

  .rightSideContainer {
    margin-left: -1.5rem;
  }

  .burgerIcon {
    display: block;
    position: fixed;
    right: 20px;
    bottom: 10px;
    z-index: 9999;
    color: #fff !important;
    padding: 8px;
    border-radius: 50% !important;
    cursor: pointer;
    background-color: #2c2c56 !important;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .leftSideContainer {
    display: none;
  }

  .rightSideContainer {
    margin-left: 2rem;
    padding: 3rem;
    margin-top: -40px;
  }

  .burgerIcon {
    display: block;
    position: fixed;
    right: 20px;
    bottom: 10px;
    z-index: 9999;
    color: #fff !important;
    padding: 8px;
    border-radius: 50% !important;
    cursor: pointer;
    background-color: #2c2c56 !important;
  }
}
