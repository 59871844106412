.wrapper {
  margin: 1rem 1rem 1.5rem 4.5rem;
  padding: 0.5rem 0;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  width: 94vw;
}

.containerTitle {
  text-align: center;
  color: #333;
}

.headerContainer {
  width: 60%;
  box-sizing: border-box;
  padding: 1rem 0;
  margin: 1rem auto;
  text-align: center;
}

.countContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0 0 0;
  background-color: #69c;
  color: white;
}

.noData {
  margin-top: 3rem !important;
  margin-bottom: -2rem !important;
  text-align: center !important;
  color: red;
  font-size: 1.1rem;
}

.tableBox {
  box-sizing: border-box;
  width: 90% !important;
  margin: 1px auto !important;
}

.tableContainer {
  max-height: 600px;
  overflow-y: auto;
  display: block;
  /* border: 1px solid black !important; */
}

.arrowContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.previousButton,
.nextButton {
  cursor: pointer;
  height: 20px !important;
  width: 20px !important;
  color: black;
}

.tableContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7) !important;
  border-radius: 10px !important;
}

.tableContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255) !important;
}

.tableHead {
  background-color: rgb(88, 147, 206) !important;
  text-align: center !important;
  color: white !important;
  font-size: 1rem !important;
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
  border-bottom: 1px solid black !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1000 !important;
  text-transform: uppercase;
}

.tableData {
  text-align: center !important;
  border: 1px solid black !important;
}

.saveBtn {
  margin: 1rem auto !important;
  display: flex !important;
  justify-content: center !important;
}

@media screen and (max-width: 600px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }

  .tableBox {
    width: 88vw !important;
    flex-direction: column;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }

  .tableBox {
    width: 90vw !important;
    flex-direction: column;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .wrapper {
    width: 90vw;
    display: flex;
    flex-direction: column;
  }

  .tableBox {
    width: 85vw !important;
    flex-direction: column;
  }
}
