.dateWiseAttendanceContainer {
  width: 75%;
  box-sizing: border-box;
  padding: 1rem 0;
  margin: 1rem auto;
}

.arrowContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 5px;
}

.previousButton {
  cursor: pointer;
  height: 20px !important;
  width: 20px !important;
  color: black;
}

.titleName,
.titleSubject {
  text-align: center;
}

.dateWiseContainerTable {
  max-height: 600px;
  overflow-y: auto;
  display: block;
  border: 1px solid black !important;
}

.tableHead {
  background-color: rgb(88, 147, 206) !important;
  text-align: center !important;
  color: white !important;
  font-size: 1rem !important;
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
  border-bottom: 1px solid black !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1000 !important;
  text-transform: uppercase;
}

.tableData {
  text-align: center !important;
  border: 1px solid black !important;
}

@media screen and (max-width: 600px) {
  .dateWiseAttendanceContainer {
    width: 90vw;
  }

  .titleName,
  .titleSubject {
    font-size: 1.2rem;
  }
}
