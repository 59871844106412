.skillsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.headingSkills {
  color: rgb(40, 44, 63);
  margin-left: 2px;
}

.headingHobbies,
.headingLanguages {
  color: rgb(40, 44, 63);
}

.horizontalRow {
  margin-top: 10px;
  width: 100%;
}

.skillsList {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.yourSkills {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  padding: 15px;
  border: 1px solid #ccc;
  width: 300px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  overflow: hidden;
  height: auto;
  line-height: 2rem;
}

.yourSkills h3 {
  text-transform: uppercase;
  width: 93%;
  word-wrap: break-word;
}

.hobbiesList h3 {
  margin-top: -3px;
  text-transform: uppercase;
}

.languagesList h3 {
  margin-top: -3px;
  text-transform: uppercase;
}

.closeIconContainer {
  position: absolute;
  top: 1px;
  right: 2px;
  z-index: 1;
}

.skillContent {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}

.skillContent h3 {
  margin-top: -1rem !important;
}

.skillContent p {
  font-family: Lato, Roboto, sans-serif;
}

.skillStats {
  margin-top: -0.5rem;
}

.noData {
  color: rgb(228, 55, 55);
  font-size: 1rem;
  margin-top: 5px;
}

.addButton {
  margin-top: 1rem !important;
  background-color: green !important;
  width: 20%;
}

.addButton:hover {
  background-color: rgb(0, 65, 0) !important;
}

.skillContent span {
  font-weight: bold;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
}

.icon {
  height: 35px;
  margin-top: -10px;
  margin-left: 5px;
  vertical-align: middle;
}

.aboutIcon {
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
  margin-top: -5px;
}

.hobbiesSection {
  margin-bottom: 2rem;
}

.hobbiesList {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  padding: 15px;
  border: 1px solid #ccc;
  width: 300px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  overflow: hidden;
  height: auto;
  line-height: 2rem;
}

.languagesList {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  padding: 15px;
  border: 1px solid #ccc;
  width: 300px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  overflow: hidden;
  height: auto;
  line-height: 2rem;
}

.yourHobbies,
.yourLanguages {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.yourHobbies p {
  font-family: Lato, Roboto, sans-serif;
}

.yourLanguages p {
  font-family: Lato, Roboto, sans-serif;
}

@media screen and (max-width: 600px) {
  .yourSkills,
  .hobbiesList,
  .languagesList {
    width: 86.5vw;
    padding: 10px;
  }

  .skillsList {
    gap: 10px;
  }

  .addButton {
    width: 60%;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .addButton {
    width: 30%;
  }

  .skillsList {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 95vw;
    gap: 20px;
  }

  .yourSkills {
    width: 40vw;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .addButton {
    width: 30%;
  }
}
