.bulkRegisterationContainer {
  height: 73vh;
  overflow: auto;
  padding-right: 16px;
  width: 100%;
}

.bulkRegisterationContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.bulkRegisterationContainer::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.bulkRegisterationContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.bulkTitle {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  text-align: start;
}

.inputGroup label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.inputGroup input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.inputbox {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(145, 145, 145);
}

.downloadTempelateButton {
  width: 80% !important;
  margin: 0.1rem auto !important;
  height: 45px !important;
  font-weight: bold !important;
  gap: 0.3rem !important;
  font-size: 0.8rem !important;
}

.uploadTempelateButton {
  width: 80% !important;
  margin: 0.1rem auto !important;
  height: 45px !important;
  font-weight: bold !important;
  /* gap: 0.3rem !important; */
  color: #fff !important;
  font-size: 0.8rem !important;
}

.saveButton {
  margin: 1rem auto !important;
  display: flex !important;
  justify-content: center !important;
}

.error {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .bulkRegisterationContainer {
    height: 65vh;
  }

  .downloadTempelateButton,
  .uploadTempelateButton {
    width: 100% !important;
    font-size: 0.7rem !important;
  }

  .inputGroup {
    width: 100% !important;
  }

  .saveButton {
    margin: 5px auto !important;
  }

  .bulkTitle {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 1160px) {
  .bulkRegisterationContainer {
    overflow: auto;
    padding-right: 16px;
    width: 100%;
    height: 67vh;
  }
}
