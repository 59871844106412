.modalBackground {
  display: table-cell;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.modal {
  padding: 20px;
  display: inline-block;
  font-weight: 300;
  position: relative;
  top: 100px;
  opacity: 0;
  animation: modalFadeIn 0.5s forwards;
  animation-delay: 0.4s;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  width: 300px;
  margin: 0 auto;
}

.closeIconContainer {
  display: flex !important;
  justify-content: end !important;
  margin-top: -15px;
  margin-right: -15px;
  margin-bottom: -10px;
}

.modalButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.modalBtn {
  width: 9.5rem;
}

.error {
  color: rgb(228, 55, 55);
  font-size: 1rem;
  margin-top: 10px;
}

@keyframes modalFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 600px) {
  .modal {
    height: auto;
  }
}
