.imgUploader {
  display: none;
}

.forToggleStyling {
  display: flex;
  justify-content: space-between;
}

.headerPersonalDetails {
  color: rgb(40, 44, 63);
  margin-left: 2px;
}

.aboutContainer,
.addressContainer {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  padding: 2rem;
  gap: 2rem;
  margin: 15px 0;
  width: 100%;
  max-width: 1520px;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 2rem;
}

.aboutHeader,
.permanentHeader,
.currentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -20px;
  width: 100%;
}

.personalDetailsContent span {
  font-weight: bold;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
}

.personalDetailsContent p {
  font-family: Lato, Roboto, sans-serif;
  font-size: 1.1rem;
}

h3 {
  font-family: Lato, Roboto, sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.currentHeader {
  margin-top: 20px;
}

.editButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Lato, Roboto, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #69c;
  border: 2px solid #69c;
  border-radius: 10px;
  background: none;
  padding: 0.2rem;
  cursor: pointer;
  width: 105px;
  flex-shrink: 0;
}

.editButton:hover {
  background-color: #69c;
  color: #ffff;
}

.horizontalRow {
  margin-top: 10px;
  width: 100%;
}

.headerAddress {
  margin-top: 3rem;
  margin-left: 2px;
  color: rgb(40, 44, 63);
}

p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.1rem;
}

.toggleSwitch {
  margin-left: 0.1rem !important;
}

.toggleSwitch label {
  font-weight: bold !important;
  font-size: 1rem !important;
  color: #494949 !important;
  margin-bottom: -15px !important;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.icon {
  height: 35px;
  margin-top: -10px;
  margin-left: 5px;
  vertical-align: middle;
}

.aboutIcon {
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
  margin-top: -5px;
}

@media screen and (max-width: 600px) {
  .aboutContainer,
  .addressContainer {
    width: 93vw !important;
  }

  .forToggleStyling {
    flex-direction: column;
  }

  .imgUploader {
    width: 92vw !important;
    display: block;
    margin: 1rem auto !important;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9) !important;
  }

  .imgUploader h3 {
    text-align: center;
  }

  .headerPersonalDetails {
    margin-top: 2rem;
  }

  .topPhotoComponent {
    margin-bottom: 2rem;
  }

  .aboutContainer {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .aboutContainer,
  .addressContainer {
    width: 93vw;
    min-width: 650px;
  }

  .imgUploader {
    width: 100%;
    display: block;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9) !important;
  }

  .imgUploader h3 {
    text-align: center;
  }

  .headerPersonalDetails {
    margin-top: 2rem;
  }

  .topPhotoComponent {
    margin-bottom: 2rem;
  }

  .aboutContainer {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .aboutContainer,
  .addressContainer {
    width: 90vw;
    min-width: 650px;
  }

  .imgUploader {
    width: 100%;
    display: block;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9) !important;
  }

  .imgUploader h3 {
    text-align: center;
  }

  .headerPersonalDetails {
    margin-top: 2rem;
  }

  .topPhotoComponent {
    margin-bottom: 2rem;
  }

  .aboutContainer {
    margin-top: 1rem;
  }
}
