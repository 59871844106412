.wrapper {
  margin: 1rem 1rem 1rem 4.5rem;
  display: flex;
}

.mainDashboard {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  width: 75%;
  height: 630px;
  overflow: auto;
}

.lectureContainer {
  width: 100%;
}

.mainDashboard::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.mainDashboard::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 15px;
}

.mainDashboard::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
}

.titleDashboard {
  word-wrap: break-word;
  text-align: center;
  margin-top: 10px;
  color: #333;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 100;
}

.cardsContainer {
  margin: 0.5rem auto !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.cards {
  background-color: #69c !important;
  color: white !important;
  padding: 3px;
  text-align: center;
  height: 190px;
  width: 250px !important;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.8) !important;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  margin: 2px auto;
}

.cards:hover {
  transform: scale(1.1);
}

.cards .cardContent {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cardHeader {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cardPara {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.lectureTime {
  margin-top: 45px;
}

.activeCard {
  background-color: #05305b !important;
  color: white !important;
  padding: 3px;
  text-align: center;
  height: 190px;
  width: 250px !important;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.8) !important;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  margin: 2px auto;
}

.activeCard:hover {
  transform: scale(1.1);
}

.activeCard .cardContent {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cards .cardContent::-webkit-scrollbar,
.activeCard .cardContent::-webkit-scrollbar {
  display: none;
}

.sideContainer {
  margin-left: 2rem;
  height: auto;
}

.calendar {
  background-color: #62866c;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  /* width: 100% !important; */
  color: #ffffff;
  scrollbar-width: thin;
  scrollbar-color: #ffffff transparent;
}

.marqueeContainer {
  height: 350px;
  width: 100%;
  max-width: 320px !important;
  background: #a33a47;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  position: relative;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.noData {
  color: red;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
}

.marqueeScrollable {
  margin-top: 0.4px !important;
  height: 50vh;
  max-height: 70vh;
  background-color: #f9fba4;
  overflow-y: auto;
  /* border-radius: 10px; */
  position: relative;
}

.marqueeScrollable::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.marqueeScrollable::-webkit-scrollbar-thumb {
  background-color: #a33a47;
  border-radius: 10px;
}

.marqueeScrollable::-webkit-scrollbar-track {
  background-color: #e2e8f0;
}

.newsTitle {
  text-align: center !important;
  color: white !important;
  margin-top: 0.2rem;
}

.marqueeContent {
  margin: 3rem 0;
  width: auto;
  min-width: 1500px !important;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* gap: 2rem; */
  max-height: 5px;
  justify-content: center;
  animation: marquee 12s linear infinite;
}

.marqueeContent :hover {
  cursor: pointer;
}

.contentInfo {
  padding: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 8px;
}

.contentInfo p {
  font-size: 1.2rem;
  color: #a33a47;
}

.contentInfo p:first-child {
  font-weight: bold;
  text-transform: uppercase;
}

.descContainer {
  /* margin-top: -1.5rem !important; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 1rem;
}

.indexNumber {
  margin-right: -1.2rem;
}

.indexNumber :hover {
  font-size: 1.8rem;
}

.pieChartContainer {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  max-width: 310px;
  margin: 1rem auto;
  padding: 0.5rem 0;
}

.pieChartTitle {
  text-align: center;
}

.pichart {
  height: 280px;
  margin-top: -2.5rem;
}

.customLegend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: -40px;
}

.legendColor {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 2px;
}

.legendLabel {
  font-size: 13.5px;
  vertical-align: middle;
}

.modalBackground {
  display: table-cell;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.modal {
  background-color: white;
  padding: 20px;
  position: relative;
  top: 100px;
  opacity: 0;
  animation: modalFadeIn 0.5s forwards;
  animation-delay: 0.4s;
  text-align: center;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  border-radius: 5px;
}

.closeIconContainer {
  display: flex;
  justify-content: end;
  margin-top: -20px;
  margin-right: -20px;
  margin-bottom: 20px;
}

.modalSubjectHeader,
.lectureTimeHeader {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-top: -30px;
  margin-bottom: 5px;
}

.modalSubCodeHeader {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-top: -5px;
  margin-bottom: 15px;
}

.modalPara {
  text-align: center;
  line-height: 1.7rem;
}

.modalButton,
.modalInactiveButton {
  margin-top: 10px !important;
  width: 220px !important;
}

.lectureModal {
  background-color: white;
  padding: 15px;
  position: relative;
  top: 150px;
  opacity: 0;
  animation: modalFadeIn 0.5s forwards;
  animation-delay: 0.4s;
  text-align: center;
  width: 350px;
  border-radius: 3px;
  margin: 0 auto;
  border-radius: 5px;
}

.markAttendanceButton {
  margin-top: 10px;
}

.noLectureContainer {
  margin: 3rem auto !important;
}

.noLecture {
  color: red;
  margin-left: 1.3rem;
  font-size: 1.1rem;
}

@keyframes modalFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }

  .mainDashboard {
    width: 95%;
    /* height: auto; */
    height: calc(90vh - 80px);
    margin: 5px auto !important;
  }

  .titleDashboard {
    font-size: 1.6rem;
  }

  .sideContainer {
    margin-left: 0;
    height: auto;
    width: 100%;
  }

  .marqueeContainer {
    margin: 1rem auto !important;
    width: 87% !important;
  }

  .modal {
    width: 80%;
    top: 10%;
    left: 5%;
    margin: 0;
  }

  .lectureModal {
    width: 80vw;
  }

  .calendar {
    width: 87% !important;
    margin-top: 1.5rem !important;
  }

  .pieChartContainer {
    max-width: 320px;
    margin-top: 1.5rem;
  }

  .customLegend {
    margin-top: -5px;
  }

  .cardContent {
    overflow-y: auto;
    height: 100%;
    scrollbar-width: thin;
    scrollbar-color: rgba(136, 136, 136, 0.5) transparent;
  }

  .cardContent {
    overflow-y: auto;
    height: 100%;
    scrollbar-width: thin;
    scrollbar-color: rgba(136, 136, 136, 0.5) transparent;
  }
}

@media screen and (min-width: 601px) and (max-width: 855px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem auto;
  }

  .mainDashboard {
    width: 95vw;
    height: calc(70vh - 80px);
    margin: 1px auto !important;
  }

  .sideContainer {
    display: flex;
    gap: 1rem;
    margin: 0.5rem;
    padding: 1rem;
    flex-wrap: wrap;
  }

  /* .pieChartContainer {
    position: absolute;
    right: 385px;
    bottom: -300px;
    width: 400px !important;
  } */

  .calendar {
    width: 45% !important;
    padding: 0.5rem !important;
  }
}

@media screen and (min-width: 856px) and (max-width: 1160px) {
  .wrapper {
    flex-direction: column;
    margin: 1rem 1rem 1rem 4.5rem;
  }

  .mainDashboard {
    width: 88vw;
    height: calc(70vh - 80px);
    margin: 1px auto !important;
  }

  .sideContainer {
    display: flex;
    gap: 1rem;
    margin: 0.5rem auto;
    padding: 1rem;
    flex-wrap: wrap;
    width: 90%;
  }

  .marqueeContainer {
    margin: 0.2px auto !important;
    width: 87% !important;
  }

  .calendar {
    width: 45% !important;
  }
}
