.editWorkExperienceContainer {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.9);
  padding: 20px 5px 20px 20px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 30%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Arial", sans-serif;
  text-align: center;
  overflow: hidden;
}

.error {
  color: rgb(228, 55, 55);
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

.closeIconContainer {
  display: flex !important;
  justify-content: end !important;
  margin-top: -20px;
  margin-right: -5px;
  margin-bottom: 15px;
}

.editWorkExperienceScrollable {
  max-height: calc(65vh - 25px);
  overflow: auto;
  padding-right: 16px;
}

.editWorkExperienceScrollable::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.editWorkExperienceScrollable::-webkit-scrollbar-thumb {
  background-color: rgba(121, 127, 205, 0.7);
  border-radius: 10px;
}

.editWorkExperienceScrollable::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.editWorkExperienceHeader {
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  margin-top: -30px;
  margin-bottom: 15px;
}

.editJobInputs {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  text-align: start;
}

.editJobInputs label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 16px;
  color: #494949;
}

.editJobInputs input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.isWorkingLabel {
  text-align: center;
  font-size: 18px !important;
}

.editJobInputs input[type="checkbox"] {
  margin-left: 11.5rem;
  margin-top: -19px;
  height: 1rem !important;
}

.textareaContainer {
  padding: 0.3rem;
  border: 1px solid #ccc;
}

@media (max-width: 600px) {
  .editWorkExperienceContainer {
    width: 95vw;
  }

  .editJobInputs label {
    font-size: 16px;
  }

  .editJobInputs input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }

  .editWorkExperienceHeader {
    margin-top: -25px;
  }

  .editJobInputs input[type="checkbox"] {
    margin-left: 12rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 1160px) {
  .editWorkExperienceContainer {
    width: 95vw;
  }

  .editJobInputs input[type="checkbox"] {
    margin-left: 12rem;
  }
}
